// import request from '@/api/request'
// const TASK_CONFIG = {
//   // 显示来源
//   showOrigin: true,
//   // 显示颜色
//   showColor: true,
//   // 显示隐私模式
//   showPrivacy: true,
//   // 显示保存草稿
//   showDraft: true,
//   // 显示发布任务
//   showPublish: true,
//   // 显示最小化
//   showMminimize: true,
//   // 显示我要使用
//   showUse: true,
//   // 显示添加到课程
//   showAddToCourse: false,
//   // 显示教学提交审核
//   showSubmitTeaching: true,
//   // 显示教学草稿按钮
//   showDraftTeaching: true,
//   // 设置教学页面状态
//   teachingPageStatue: 'start', // start 开始制作, price 设置, make 制作
//   // 设置控件区状态
//   sideBarType: '', // widget 控件 teach 教学 chat 聊天 record 记录
//   // 添加到课程的回调
//   addToCourseCallback: undefined,
// }

const task = {
  state: {
    info: undefined, // 当前编辑的任务信息
    task: undefined,
    task_original: undefined,
    // 教学审核方法回调
    checkMethod: undefined,
    // 教学提交审核回调
    submitCheckMethod: undefined,
    // taskConfig: Object.assign(
    //   {},
    //   TASK_CONFIG,
    // ),
  },
  mutations: {
    setTaskInfo (state, data) {
      state.info = data
    },
    setTaskModuleTask (state, data) {
      state.task = data
    },
    setTaskModuleTaskOriginal (state, data) {
      state.task_original = data
    },
    setTaskModuleCheckMethod (state, data) {
      state.checkMethod = data
    },
    setTaskModuleSubmitCheckMethod (state, data) {
      state.submitCheckMethod = data
    },
    // 如果不传data 则重置
    // setTaskModuleTaskConfig (state, data) {
    //   if (data) {
    //     state.taskConfig = Object.assign(
    //       {},
    //       state.taskConfig,
    //       data,
    //     )
    //   } else {
    //     state.taskConfig = Object.assign(
    //       {},
    //       TASK_CONFIG,
    //     )
    //   }
    //
    // },
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
  }
}

export default task
