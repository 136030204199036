<script>
import CoursePackage from '@/models/cource_package/index'

import store from '@/store'
export default {
  props: {
    coursePackage: {
      type: CoursePackage,
      default: () => {
        return {}
      }
    },
    showCheckAndTime: {
      type: Boolean,
      default: true
    },
    canRecover: { // 可以恢复
      type: Boolean,
      default: false,
    },
    canDelete: { // 可以删除
      type: Boolean,
      default: true,
    },
  },
  inject: [
    'alertEdit',
  ],
  data () {
    return {
      switchMenu: false,
    }
  },
  computed: {
    title () {
      return this.coursePackage.title
    },
    showCheckStatus () {
      const {
        coursePackage
      } = this
      const {
        checkstatus,
        checkType,
      } = coursePackage
      if (checkType === 1) {
        return false
      }
      if (!checkstatus || checkstatus === 1) {
        return false
      }
      return true
    },
    statusType () {
      const {
        coursePackage
      } = this
      const {
        checkstatus
      } = coursePackage
      if (checkstatus === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return 'audit'
      }
      if (checkstatus === 3) {
        return 'approve'
      }
      if (checkstatus === 4) {
        return 'fail'
      }
      return ''
    },
    statusText () {
      const {
        coursePackage
      } = this
      const {
        checkstatus
      } = coursePackage
      if (checkstatus === 1) {
        return ''
      }
      if (checkstatus === 2) {
        return '审核中'
      }
      if (checkstatus === 3) {
        return '审核通过'
      }
      if (checkstatus === 4) {
        return '审核不通过'
      }
      return ''
    },
  },
  methods: {
    touchRecover () {
      this.$emit('handleRecover', this.coursePackage)
    },
    touchCoursePackage () {
      this.switchMenu = false
      const {
        coursePackage
      } = this
      const {
        id
      } = coursePackage
      if (id) {

        //
        if (this.alertEdit) {
          store.commit('setCoursePackageOperationData', coursePackage)
          this.$modal.show('globalCoursePackageEdit')
        } else {
          store.commit('setCoursePackageData', coursePackage)
        }
      }
    },
    touchDelete () {
      this.switchMenu = false
      store.commit(
        'setCoursePackageOperationData',
        this.coursePackage
      )
      this.handleClose()
      // 打开新的
      this.$modal.show('globalDeleteCoursePackage')
    },
    handleClose() {
      // console.log('Popover is closing');
      // 可以在这里做一些清理工作
      this.switchMenu = false; // 确保 Popover 关闭
    },
    /**
     * 触发分享功能
     * 此函数通过调用API启动分享流程，具体分享课程包信息
     * 类型参数为5，表明这是一种特定类型的数据分享
     */
    touchShare () {
      this.$api.share.doStartShare({
        data: Object.assign(
          {},
          this.coursePackage,
        ),
        type: 5
      });
      this.handleClose()
    },
  }
}

</script>

<template>
  <div
    class="course_package d-flex flex-column bbox_wrapper"
  >
    <el-popover
      v-model="switchMenu"
      placement="right"
      trigger="hover"
      width="100"
      @close="handleClose"
      popper-class="v_fum_template_resource_course_package_popover"
    >
      <div
        class="v_course_package_menu_list_group"
      >
        <div
          v-if="canDelete"
          class="btn cp"
          @click.stop="touchDelete"
        >
          删除
        </div>
        <div
          v-if="canRecover"
          class="btn cp"
          @click.stop="touchRecover"
        >
          恢复
        </div>
        <div
          class="btn cp"
          @click.stop="touchShare"
        >
          分享
        </div>

      </div>
      <template
        slot="reference"
      >
        <div
          class="menu_icon_group d-flex align-center justify-center"

        >
          <div
            class="menu"
          ></div>
        </div>
      </template>
    </el-popover>
    <div
      class="title_group d-flex align-center justify-space-between"
      @click.stop="touchCoursePackage"
    >
      <div
        class="title"
      >
        {{ title }}
      </div>
      <div
        v-if="showCheckStatus && 0"
        class="check_status"
      >
        {{ statusText }}
      </div>
    </div>
    <div
      class="pic_group"
      @click.stop="touchCoursePackage"
    >
      <template
        v-if="coursePackage.webpic"
      >
        <div

          :style="{
          backgroundImage: `url(${ $file_url }${ coursePackage.webpic })`
        }"
          class="pic"
        >
        </div>
        <div
          :style="{
          backgroundImage: `url(${ $img_url }${ coursePackage.webpic })`
        }"
          class="pic img_pic"
        >
        </div>
      </template>


      <div
        v-else
        class="text_pic un_sel d-flex align-center justify-center"
      >
        {{ coursePackage.title }}
      </div>
    </div>
    <div
      v-if="showCheckAndTime"
      class="info_group d-flex align-center justify-space-between"
    >
      <div
        class="time"
      >
        {{ $moment(coursePackage.updateTime || coursePackage.createTime).format('YYYY/MM/DD HH:mm') }}
      </div>
      <div
        v-if="showCheckStatus"
        class="status_group d-felx align-center"
        :class="{
          [statusType]: true
        }"
      >
        <div
          class="icon"
        ></div>
        <div
          class="text"
        >
          {{ statusText }}
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.course_package {
  margin: {
    right: 10px;
    bottom: 15px;
  }
  width: 280px;
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.06);
  border-radius: 10px;
  border: 1px solid #E8EEFF;
  background-color: #fff;
  overflow: hidden;
  padding: 20px;
  position: relative;
  &:hover {
    .menu_icon_group{
      opacity: 1;
    }
  }
  .menu_icon_group {
    @include transition;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 0 8px 0 0;
    z-index: 9;
    .menu {
      position: relative;
      width: 4px;
      height: 4px;
      background: #CCCCCC;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 8px;
        width: 4px;
        height: 4px;
        background: #ccc;
      }
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 8px;
        width: 4px;
        height: 4px;
        background: #ccc;
      }
    }
  }
  .title_group {
    @include single_line_intercept;
    flex-shrink: 0;
    text-align: left;
    width: 100%;
    font-size: 16px;
    color: #333333;
    line-height: 20px;
    font-style: normal;
    padding: {
      bottom: 15px;
      right: 10px;
    }
    .check_status {
      font-size: 12px;
      line-height: 16px;
      color: #05AD00;
    }
  }
  .pic_group {
    width: 240px;
    height: 135px;
    background: #D8D8D8;
    position: relative;
    .text_pic {
      @include single_line_intercept;
      width: 100%;
      height: 100%;
      background-color: #cccccc;
      color: #fff;
      font-size: 30px;
      font-weight: bold;
      padding: 10px;
    }
    .pic {
      @include background;
      width: 100%;
      height: 100%;
      background-size: contain;
      position: relative;
      &.img_pic {
        position: absolute;
        z-index: 3;
        top: 0;
        left: 0;
      }
    }

  }
  .info_group {
    width: 100%;
    padding-top: 12px;
    .time {
      font-size: 12px;
      color: #666666;
      line-height: 16px;
      text-align: left;
      font-style: normal;
    }
    .status_group {
      &.fail {
        color: #D82E0D;
        .icon {
          border-color: #D82E0D;
        }
      }
      &.audit {
        color: #F78900;
        .icon {
          border-color: #F78900;
        }
      }
      &.approve {
        color: #05AD00;
        .icon {
          border-color: #05AD00;
        }
      }
      .icon {
        margin-right: 6px;
      }
      .text {
        font-size: 12px;
        line-height: 16px;
        text-align: left;
        font-style: normal;
        text-transform: uppercase;
      }
    }
  }
}
</style>
<style lang="scss">
.v_course_package_menu_list_group {
  padding: 0!important;
  .btn {
    font-size: 14px;
    color: #333;
    line-height: 40px;
    padding: 0 10px;
    min-width: 60px;
    &:hover {
      background-color: #f2f2f2;
    }
  }
}
.v_fum_template_resource_course_package_popover {
  width: auto!important;
  min-width: 60px!important;
  padding: 0!important;
}
</style>
