<template>
  <div
    class="sketch_picture_page"
  >
    <template
      v-if="img_list && img_list.length && msg.files != '' && msg.filesNum != 0"
    >
      <div class="list d-flex flex-wrap">
        <template
          v-for="(item,index) in img_list"
        >
          <!--<div-->
          <!--  :key="index"-->
          <!--  class="pic_wrapper"-->
          <!--&gt;-->
            <!--{{ getUrlExtension(item) }}-->
            <el-image
              v-if="index<5"
              class="picture flex-shrink-0"
              style="width: 100px; height: 100px"
              :lazy="true"
              :src="`${item}`"
              fit="cover"
              :preview-src-list="img_list"
              @click.native="$api.stopPropagation($event)"
            ></el-image>
            <!--<img-->
            <!--  v-if="index<5 && getUrlExtension(item) === 'heic'"-->
            <!--  class="picture flex-shrink-0"-->
            <!--  :src="`${item}`"-->
            <!--  alt=""-->
            <!--  @click="$api.stopPropagation($event)"-->
            <!--/>-->
          <!--</div>-->


        </template>
        <!-- 更多 -->
        <div class="picture six_group"
          v-if="six_status"
          @click="$api.stopPropagation($event)"
        >
          <div class="more_group d-flex align-center justify-center"
            @click="getMore($event)"
            v-if="is_more && !switch_more"
          >
            <div class="text">+{{more_number}}</div>
          </div>
          <!-- 只有6个 -->
          <el-image
            class="six flex-shrink-0"
            style="width: 100px; height: 100px"
            :src="img_list[5]"
            fit="cover"
            :preview-src-list="img_list"
          ></el-image>
        </div>

        <!-- 展示更多 -->
        <template
          v-if="switch_more"
        >
          <template
            v-for="(item,index) in img_list"
          >
            <el-image
              :key="index"
              class="picture flex-shrink-0"
              style="width: 100px; height: 100px"
              :src="`${item}`"
              fit="cover"
              :preview-src-list="img_list"
              v-if="index>5"
              @click.native="$api.stopPropagation($event)"
            ></el-image>
          </template>
        </template>

      </div>
      <div
        class="pack_up"
        v-if="is_more && switch_more"
        @click="switch_more=false;$api.stopPropagation($event)"
      >收起</div>
    </template>

    <common-sketch
      v-else
      :msg="msg"
    ></common-sketch>
  </div>
</template>
<script>
import CommonSketch from './Common.vue';
import heic2any from 'heic2any';
export default {
  props: {
    msg: {
      type: Object,
      default: null,
    }
  },
  components: {
    CommonSketch,
  },
  data(){
    return {
      img_list: [],
      img_list_obj: [],
      switch_more: false,
      scroll_container: null,
    }
  },
  computed:{
    is_more(){
      const {img_list} = this;
      if(img_list.length > 6){
        return true;
      }
      return false;
    },
    more_number(){
      const {img_list} = this;
      if(img_list.length > 6){
        return img_list.length - 5;
      }
      return 0;
    },
    six_status(){
      const {img_list} = this;
      if(img_list.length >= 6){
        return true;
      }
      return false;
    }
  },
  watch: {
    msg: function(val){
      // // console.log(val);
      if(val){
        this.generateImgList();
      }
    }
  },
  methods: {
    generateImgList(){
      const { msg, $img_url } = this;
      const { files } = msg;
      let img_list = [],
        img_list_obj = [];
      if(!files){
        return;
      }

      const files_split = files.split('|');
      // // console.log(files_split);
      files_split.forEach(item => {
        const obj = item.split('>');
        img_list.push(`${$img_url}${obj[0]}`);
        // 获取文件的后缀名
        const fileExtension = obj[1].split('.').pop();
        img_list_obj.push({
          url: `${$img_url}${obj[0]}`,
          filename: obj[1],
          type: obj[2],
          fileExtension,
        })
      })

      console.log(img_list);
      console.log(img_list_obj);
      this.$set(this, 'img_list', img_list);
    },
    getMore(e){
      e.stopPropagation;
      this.switch_more = true;
    },
    // 获取url的后缀
    getUrlExtension(url) {
      return url.split('.').pop();
    },
    async handleFileChange(heicUrl) {
      if (heicUrl) {
        try {
          // 发起请求获取 .heic 图片的二进制数据
          const response = await fetch(heicUrl);
          const blob = await response.blob();
          if (blob.type === 'image/heic') {
            // 转换 .heic 图片为 jpeg 格式
            const convertedBlob = await heic2any({
              blob,
              toType: 'image/jpeg',
              quality: 0.9
            });
            const reader = new FileReader();
            reader.onloadend = () => {
              // this.imageUrl = reader.result;

            };
            reader.readAsDataURL(convertedBlob);
          }
        } catch (error) {
          console.error('Error converting HEIC:', error);
        }
      }
    }
  },
  mounted() {
    this.generateImgList();
    this.$set(this, 'scroll_container', document.querySelector('#fun_task_dialog'))
    // // console.log(document.querySelector('#fun_task_dialog'));
  },
}
</script>

<style lang="scss" scoped>
.sketch_picture_page{
  .list{
    @include bbox;
    padding: 10px 0 0;
    max-width: 650px;
  }
  .pack_up{
    font-size: 12px;
    color:$main_blue;
  }
  .picture{
    width: 100px;
    height: 100px;
    margin: {
      right: 10px;
      bottom: 10px;
    }
    &:last-child{
      margin-right: 0;
    }
    &:nth-of-type(6n){
      margin-right: 0;
    }
  }
  .six_group{
    position: relative;
    .more_group{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0,0,0,0.66);
      z-index: 3;
      .text{
        color: #fff;
        font-size: 14px;
        font-weight: bold;
      }
    }
    .six{
      width: 100px;
      height: 100px;
      position: relative;
      z-index: 2;
    }
  }
}
</style>
