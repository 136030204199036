// import request from '@/api/request'
const TASK_CONFIG = {
  // 显示来源
  showOrigin: true,
  // 显示颜色
  showColor: true,
  // 显示隐私模式
  showPrivacy: true,
  // 显示保存草稿
  showDraft: true,
  // 显示发布任务
  showPublish: true,
  // 显示最小化
  showMminimize: true,
  // 显示我要使用
  showUse: true,
  // 显示添加到课程
  showAddToCourse: false,
  // 显示教学提交审核
  showSubmitTeaching: true,
  // 显示教学草稿按钮
  showDraftTeaching: true,
  // 设置教学页面状态
  teachingPageStatue: 'start', // start 开始制作, price 设置, make 制作
  // 设置控件区状态
  sideBarType: 'widget', // widget 控件 teach 教学 chat 聊天 record 记录
  // 添加到课程的回调
  addToCourseCallback: undefined,
  // 显示前往维护
  showGoToMaintain: true,
}

const task = {
  state: {

    task: undefined,
    task_original: undefined,
    // 教学审核方法回调
    checkMethod: undefined,
    // 教学提交审核回调
    submitCheckMethod: undefined,
    taskConfig: Object.assign(
      {},
      TASK_CONFIG,
    ),
    // 当前数据
    info: undefined, // 当前编辑的任务信息
    details: [],
    users: [], // 参与人
    // 编辑的数据
    eidtInfo: undefined,
    editDetails: [],
    editUsers: [],


  },
  mutations: {
    setTaskInfo (state, data) {
      state.info = data
    },
    setTaskModuleTask (state, data) {
      state.task = data
    },
    setTaskModuleTaskOriginal (state, data) {
      state.task_original = data
    },
    setTaskModuleCheckMethod (state, data) {
      state.checkMethod = data
    },
    setTaskModuleSubmitCheckMethod (state, data) {
      state.submitCheckMethod = data
    },
    // 如果不传data 则重置
    setTaskModuleTaskConfig (state, data) {
      if (data) {
        state.taskConfig = Object.assign(
          {},
          state.taskConfig,
          data,
        )
      } else {
        state.taskConfig = Object.assign(
          {},
          TASK_CONFIG,
        )
      }

    },
    setTasksCheckMethod (state, data) {
      state.checkMethod = data
    },
    setTasksCheckStatus (state, data) {
      state.checkStatus = data
    },
    setTasksSubmitCheckMethod (state, data) {
      state.submitCheckMethod = data
    },
    setTasksSubmitCheckStatus (state, data) {
      state.submitCheckStatus = data
    },
    setTasksInfo (state, data) {
      state.info = data
    },
    setTasksDetails (state, data) {
      // 如果data为空 则重置为[]
      if (!data) {
        data = []
      }
      state.details = data
    },
    setTasksUsers (state, data) {
      // 如果data为空 则重置为[]
      if (!data) {
        data = []
      }
      state.users = data
    },
    setTasksEditInfo (state, data) {
      state.eidtInfo = data
    },
    setTasksEditDetails (state, data) {
      // 如果data为空 则重置为[]
      if (!data) {
        data = []
      }
      state.editDetails = data
    },
    setTasksEditUsers (state, data) {
      // 如果data为空 则重置为[]
      if (!data) {
        data = []
      }
      state.editUsers = data
    },
  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
  }
}

export default task
