import Vue from 'vue'
import Vuex from 'vuex'
import state from './state'
import mutations from './mutations'
import getters from './getter'
import actions from './action'
import users from './modules/user'
import task_module from './modules/task_module'
import contact from './modules/contact'
import template from './modules/template'
import im_login from './modules/im_login'
import im_chat from '@/store/modules/im_chat'
import control from '@/store/modules/control'
import common from '@/store/modules/common'
import ar from '@/store/modules/ar'
import app from '@/store/modules/app'
import course_package from '@/store/modules/course_package'
import task from '@/store/modules/task'
import tasks from '@/store/modules/tasks'
import resource from '@/store/modules/resource'
import pageStatus from '@/store/modules/page_status'
import collect from '@/store/modules/collect'

Vue.use(Vuex)




// 导出 store 对象
export default new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
  modules:{
    task_module,
    contact,
    template,
    im_login,
    im_chat,
    control,
    common,
    ar,
    app,
    course_package, // 教学包
    task,
    tasks,
    resource, //
    pageStatus, // 页面状态
    collect, // 收藏
  }
})

// import Vue from "vue";
// import Vuex from "vuex";

// import user from "./modules/user";

// Vue.use(Vuex);

// const store = new Vuex.Store({
//   state: {
//     //
//   },
//   mutations: {
//     //
//   },
//   actions: {},
//   modules: {
//     user
//   }
// });

// export default store;
