import vue from '@/main.js';
import sendTaskFun from './sendTaskFun';
/**
 *
 * @returns
 */
const createTask =  async (params)=>{
  let create_draft = params?.create_draft
  // TODO 增加判断 创建教学 / 创建实例 // 创建草稿
  // 教学 => task_type:2, 新增price
  // 实例 => task_type:3, 新增teach_id //实例需要选择对话窗口

  let url = '/projectTask/createTask'
  const { task, task_config } = vue.$store.state
  const {
    create_teach,
    price,
    select_target,
    select_target_chat_id,
    select_target_chat_type,
    create_instance,
    introduction,
    task_id,
    teachType,
  } = task_config
  if (task_config.create_draft) {
    create_draft = task_config.create_draft
  }
  const {
    levelStatus,
    processStatus,
    teachingType
  } = task.task
  // // console.log(task_config.task_name === '');
  console.log(task_config)
  // 重新选择对象  && 不是教学 && 不是创建草稿
  if (select_target && !create_teach && !create_draft) {
    if ((select_target_chat_id === null || select_target_chat_type === null) ) {
      vue.$notice({desc: '请选择发送对象'})
      return false
    }
  }

  if (task_config.task_name === '') {
    vue.$notice({desc: '请输入任务标题'})
    return false;
  }
  if (sendTaskFun.uploaded() === false) {
    vue.$notice({desc: '有文件正在上传, 请稍候再发布'})
    return false
  }
  let data = {
    name: task_config.task_name,
    task_type: 1,
    hide: task_config.privacy ? 1 : 0,
    colour: task_config.color,
    controls: sendTaskFun.generateControls(),
    to_users: sendTaskFun.generateToUsers(),
    chat_type: task_config.chat_type,
    chat_id: sendTaskFun.generateChatId(),
    addtags: task_config.addtags
    // levelStatus,
    // processStatus
  }
  if (levelStatus) {
    data.levelStatus = levelStatus
  }
  if (processStatus) {
    data.processStatus = processStatus
  }

  // console.log(task, task_config, params)
  // return
  if (create_teach) {
    // 如果是普通模板 则将其转为实例
    if (task_id) {
      data.taskId = task_id
    }
    data.chat_id = 0 // 创建教学
    data.chat_type = 0
    data.task_type = 2
    data.price = price
    data.to_users = ''
    data.introduction = introduction
    data.teachType = teachType
    data.teachingType = teachingType
    delete data.hide
    delete data.color
    console.log(task_config)
  }
  if (create_draft) {
    url = '/projectDraft/draftCreate'
    delete data.chat_type
    delete data.chat_id
    delete data.hide
    delete data.to_users
    data.colour = task_config.color
    if (create_teach) {
      url = '/resource/createTeachTask'

      // 如果选择专人审核 则需要将审核人id传入

      // 资源库审核

      // 无需审核
    }
  }

  // 重新选择对象  && 不是教学 && 不是创建草稿
  if (select_target && !create_teach && !create_draft) {
    // if((select_target_chat_id === null || select_target_chat_type === null) ){
    //   vue.$notice({desc: '请选择发送对象'});
    //   return false;
    // }
    data.chat_id = select_target_chat_id
    data.chat_type = select_target_chat_type
  }
  if (select_target_chat_id !== null && select_target_chat_type != null) {
    data.chat_id = select_target_chat_id
    data.chat_type = select_target_chat_type
  }
  if (create_instance) {
    // // console.log('create_instance')
    // // console.log(task.task.teachId , task.task.id)
    data.hide=task_config.privacy ? 1 : 0
    data.task_type = 3
    data.colour = task_config.color
    data.teach_id = task.task.teachId || task.task.id
    data.introduction = introduction
  }

  const res = await vue.$global.doPost({
    url,
    data,
    notice: '发布失败',
    preventDuplicateClicks: true,
  })
  // return;
  await sendTaskFun.checkReminder({res})
  return res
}
export default createTask


