<template>
  <div class="success-con">
    <div class="success-icon">
      <img src="../../assets/icon-success.png" alt class="icon" />
      <h2 class="fon">注册成功，恭喜您成为我们的普通注册用户！</h2>
      <div class="data">
        <button class="personal cp" @click="information">继续完善个人信息</button>
        <button class="major cp" @click="kaishigoutong">开始专业沟通</button>
      </div>
      <p class="xian" v-if="0"></p>
    </div>
    <div class="vip" v-if="0">
      <div class="vip-right">
        <div class="iconvip">
          <h3 class="yh">或者 您还可以选择成为VIP用户</h3>
          <img src="../../assets/icon-vip.png" alt class="iconv" />
        </div>
        <p class="ftion">成为VIP用户后，您将享受以下功能：</p>
        <div class="gn">
          <ul>
            <li>主动添加联系人功能</li>
            <li>新建一对一和工作组对话窗口功能</li>
            <li>新建模板功能</li>
            <li>收藏及管理模块功能</li>
            <li>申请签约讲师,共享教学收益(暂未上线,敬请期待....)</li>
            <li>享受教学内容VIP用户价</li>
          </ul>
        </div>
        <a  @click="equities">更多权益....</a>
      </div>
      <div class="line"></div>
      <div class="vip-left">
        <img src="../../assets/388.png" alt class="money" />
        <div class="code">
          <div class="coder">
            <button class="er"></button>
            <div class="weixin">
              <img src="../../assets/weixin.png" alt class="wx" />
              <p class="wzf">微信支付</p>
            </div>
          </div>
          <div class="linex"></div>
          <div class="codel">
            <button class="zf"></button>
            <div class="zfb">
              <img src="../../assets/zhifubao.png" alt class="bao" />
              <p class="zfub">支付宝扫码支付</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <identification-code
      v-if="switch_identification_code"
      :loginUserCode="loginUserCode"
      :user="user"
      @handleClose="closeIdentificationCode();"
      @handleSuccess="identifySuccess()"
    ></identification-code>
  </div>
</template>

<script>
import IdentificationCode from '@/components/PopUp/identificationCode/index.vue';
export default {
  components: {
    IdentificationCode,
  },
  beforeDestroy(){

  },
  mounted(){
    // window.onbeforeunload = () => {
    //   this.$router.push('/login/sign');
    //   return;
    // }
    this.$set(this, 'user', this.$tools.getCookie('user'));
    this.$set(this, 'loginUserCode', this.$tools.getCookie('loginUserCode'));
    // this.$set(this, 'IDENTIFYUSERCODE', this.$tools.getCookie('IDENTIFYUSERCODE'));
  },
  data(){
    return{
      switch_identification_code: false,
      user: null,
      loginUserCode: null,
      // IDENTIFYUSERCODE: null,
    }
  },
  methods: {
    equities() {
      this.$router.push("/home/about");
    },
    information() {
      // this.$router.replace("/home/information");
      this.$tools.removeCookie('autologin');
      if(!this.user.usercode){
        this.switch_identification_code = true;
        return;
      }
      this.$router.push("/home/information")
    },
    kaishigoutong(){
      this.$tools.removeCookie('autologin');

      if(!this.user.usercode){
        this.switch_identification_code = true;
        return;
      }
      this.$router.push('/')
    },
    closeIdentificationCode(){
      this.switch_identification_code = false;
    },
    async identifySuccess(){
      this.closeIdentificationCode();
      this.$showLoading({title: '加载中'});
      // await this.login();
      await this.$api.user.refreshUserData();
      this.$hideLoading();
      this.$notice({desc: '识别成功!', type: 'success'});
      this.$router.push('/')
    }
  }
};
</script>

<style lang="less" scoped>
.success-con {
  display: flex;

  width: 1200px;
  // height: 650px;
  height: 260px;
  background: rgba(255, 255, 255, 1);
  box-shadow: 0px 0px 30px 0px rgba(255, 255, 255, 0.43);
  margin: 200px auto;

  .success-icon {
    // width: 1200px;
    // height: 224px;

    .icon {
      width: 126px;
      height: 126px;
      margin-left: 537px;
      margin-top: -62px;
    }

    .fon {
      width: 480px;
      height: 25px;
      font-size: 24px;
      margin-left: 368px;
    }

    .data {
      .personal {
        width: 289px;
        height: 50px;
        background: rgba(0, 84, 166, 1);
        border-radius: 4px;
        font-size: 18px;
        color: rgba(255, 255, 255, 1);
        border: none;
        margin-left: 301px;
        margin-top: 34px;
      }
      .major {
        width: 290px;
        height: 50px;
        background: #e6eef4;
        border-radius: 4px;
        font-size: 18px;
        color: rgba(0, 84, 166, 1);
        border: none;
        margin-left: 20px;
      }
    }
    .xian {
      width: 1200px;
      height: 2px;
      background: rgba(240, 240, 240, 1);
      margin-top: 45px;
    }
  }

  .vip {
    width: 1200px;
    height: 426px;
    display: flex;

    .line {
      width: 2px;
      height: 320px;
      background: rgba(240, 240, 240, 1);
      margin-top: 57px;
    }
    .vip-right {
      .iconvip {
        display: flex;

        .yh {
          width: 282px;
          height: 22px;
          font-size: 20px;
          color: #333333;
          margin-left: 80px;
          margin-top: 60px;
          text-align: center;
        }
        .iconv {
          width: 41px;
          height: 33px;
          margin-top: 64px;
        }
      }

      .ftion {
        width: 287px;
        height: 19px;
        margin-left: 80px;
        font-size: 16px;
        font-weight: bold;
        color: #333333;
        margin-bottom: 19px;
      }
      .gn {
        width: 456px;
        height: 159px;
        margin-left: 81px;
        font-size: 16px;
        li {
          margin-top: 5px;
        }
      }
      a {
        font-weight: 400;
        text-decoration: underline;
        color: #0054a6;
        font-size: 18px;
        margin-left: 80px;
      }
    }
    .vip-left {
      .money {
        display: block;
        width: 306px;
        height: 80px;
        margin-left: 147px;
        margin-top: 50px;
      }
      .code {
        display: flex;

        .coder {
          .er {
            width: 152px;
            height: 152px;
            background: pink;
            margin-top: 56px;
            margin-left: 50px;
          }
          .weixin {
            // width: 128px;
            height: 20px;
            display: flex;

            .wx {
              width: 20px;
              height: 20px;
              margin-left: 60px;
              margin-top: 10px;
            }
            .wzf {
              display: block;
              width: 108px;
              height: 19px;
              font-size: 16px;
              margin-top: 10px;
              margin-left: 3px;
            }
          }
        }
        .linex {
          width: 2px;
          height: 150px;
          background: #eeeeee;
          margin-left: 85px;
          margin-top: 60px;
        }
        .codel {
          .zf {
            width: 152px;
            height: 152px;
            background: orange;
            margin-top: 56px;
            margin-left: 93px;
          }
          .zfb {
            // width: 128px;
            height: 20px;
            display: flex;

            .bao {
              width: 20px;
              height: 20px;
              margin-top: 10px;
              margin-left: 103px;
            }

            .zfub {
              width: 125px;
              height: 19px;
              font-size: 16px;
              color: #333333;
              margin-top: 10px;
              margin-left: 3px;
            }
          }
        }
      }
    }
  }
}
</style>
