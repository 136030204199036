<!--
 * @Author: hugo 1634765756@qq.com
 * @Date: 2022-05-19 15:13:57
 * @LastEditors: hugo 1634765756@qq.com
 * @LastEditTime: 2022-05-23 16:31:14
 * @FilePath: /miaohang/src/components/PersonalCenter/Plugins/NotMember/Wrapper.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="c_personal_center_plugins_not_member_index bbox">
    <div class="main bbox">
      <div class="hint" v-if="hint">{{hint}}</div>
      <div class="list_group bbox">
        <div class="title">成为VIP用户享受以下功能：</div>
        <div class="entitlemen_table d-flex align-center flex-wrap un_sel">
          <template
            v-for="(item,index) in entitlemen_table"
          >
            <div class="item d-flex align-center"
              :key="index"
            >
              <img
                :src="require(`@/assets/images/personal_center/vip/vip${item.pic}.png`)" alt="" class="img mr-10"
              >
              <div class="text">{{item.text}}</div>
            </div>
          </template>
        </div>
      </div>
      <div class="btn_group bbox d-flex align-center">
        <div class="button member mr-10"
          @click.stop="becomeMember()"
        >立即开通VIP会员</div>
        <div class="button close cp"
          @click.stop="$emit('handleClose')"
        >我再想想</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    hint: {
      type: String,
      default: undefined,
    }
  },
  data(){
    return {
      entitlemen_table: [
        {text: '查看联系人能力画像的具体数值', pic: 1},
        {text: '创建任务自定义标签', pic: 4},
        {text: '收藏“模板商城”中的任务', pic: 2},
        {text: '在“我收藏的”中创建文件夹', pic: 5},
        {text: '使用“智慧桌”控件', pic: 3},
      ]
    }
  },
  methods: {
    becomeMember(){
      this.$emit('handleClose')
      this.$become_member()
    }
  }
}
</script>

<style lang="scss" scoped>
.c_personal_center_plugins_not_member_index{

  .main{
    @include hidescrollbar;
    flex   : 1;
    width  : 100%;
    padding: 0 40px;
    .hint{
      width: 100%;
      font-size: 14px;
      color: #555555;
      line-height: 22px;
      padding-bottom: 20px;
    }
    .list_group{
      max-width: 660px;
      padding: 20px;
      background: #F8F8F8;
      border-radius: 4px;
      margin-bottom: 20px;
      .title{
        font-size: 16px;
        color: #333333;
        line-height: 21px;
        padding-bottom: 20px;
      }
      .entitlemen_table{
        .item{
          width: 49%;
          margin-right: 1%;
          margin-bottom: 20px;
        }
        .img{
          width: 40px;
          height: 40px;
        }
        .text{
          font-size: 14px;
          color: #555555;
          line-height: 19px;
        }
      }
    }
    .btn_group{
      padding-bottom: 20px;
      .button{
        padding: 0 20px;
        line-height: 38px;
        border-radius: 4px;
        border: 1px solid transparent;
        font-size: 14px;
      }
      .close{
        border: 1px solid #605853;
        color:#2F2E32;
      }
      .member{
        color: #FFDC9D;
        background: linear-gradient(135deg, #605853 0%, #2F2E32 100%);
      }
    }
  }
}
</style>
