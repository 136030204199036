<template>
  <div
    v-if="pPackage && packageSelectTask"
    class="c_teaching_package_select_task_index bbox d-flex flex-column"
    @click.stop="touchWrapper"
  >
    <div
      ref="mainGroup"
      class="main_group bbox"
      id="c_teaching_package_select_task_index_main_group"
    >
      <div class="sticky_group bbox">
        <filter-bar
          ref="filterBar"
          :package-select-task="packageSelectTask"
          @handleDoSearch="handleDoSearch"
        ></filter-bar>
        <router-bar
          v-show="showRouterBar"
          class="pt-10"
          :package-select-task="packageSelectTask"
        ></router-bar>
        <all-group
          v-show="showAll"
          class="py-10"
          :task-list="taskList"
          :package-select-task="packageSelectTask"
        ></all-group>
      </div>
      <div class="container">
        <task-list
          v-show="showTask"
          :package-select-task="packageSelectTask"
          :task-list="taskList"
          @handleLoadmore="loadmore"
        ></task-list>
        <package-list
          v-show="showPackage"
          :package-select-task="packageSelectTask"
          :package-list="packageList"
          :p-package="pPackage"
          @handleLoadmore="loadmore"
          @handleGetPackageTaskList="getPackageTaskList"
          @handleClearFilterBarSearch="clearFilterBarSearch"
        ></package-list>
      </div>
    </div>
    <operation-bar
      class="flex-shrink-0"
      :p-package="pPackage"
      :package-select-task="packageSelectTask"
    ></operation-bar>
  </div>
</template>

<script>
import tools from '@/api/tools'
import FilterBar from './FilterBar'
import RouterBar from '@/components/PopUp/TeachingPackage/SelectTask/RouterBar'
import AllGroup from '@/components/PopUp/TeachingPackage/SelectTask/AllGroup'
import TaskList from '@/components/PopUp/TeachingPackage/SelectTask/TaskList'
import PackageList from './PackageList'
import OperationBar from '@/components/PopUp/TeachingPackage/SelectTask/OperationBar'
import { listForClasses, PTask, PPackage } from '@/types'
export default {
  name: 'index',
  components: {
    FilterBar,
    RouterBar,
    AllGroup,
    TaskList,
    PackageList,
    OperationBar
  },
  props: {
    pPackage: {
      type: Object,
      default: undefined
    },
    packageSelectTask: {
      type: Object,
      default: undefined
    }
  },
  computed: {
    showAll() {
      const { packageSelectTask } = this
      const { origin, type, pPackage } = packageSelectTask
      if (origin === 'shopping' && type === 'package' && pPackage) {
        return true
      }
      return false
    },
    showRouterBar() {
      const { packageSelectTask } = this
      const { origin, type, pPackage } = packageSelectTask
      if (origin === 'shopping' && type === 'package' && pPackage) {
        return true
      }
      return false
    },
    origin() {
      const { packageSelectTask } = this
      const { origin } = packageSelectTask
      return origin
    },
    type() {
      const { packageSelectTask } = this
      if (!packageSelectTask) {
        return ''
      }
      const { type } = packageSelectTask
      return type
    },
    sellList() {
      const { packageSelectTask } = this
      const { sellList } = packageSelectTask
      return sellList
    },
    taskList() {
      const { packageSelectTask } = this
      const listFieldName = this.getListFieldName()
      return packageSelectTask[listFieldName]
    },
    shoppingPackage() {
      const { packageSelectTask } = this
      const { shoppingPackage } = packageSelectTask
      // console.log(sellList)
      return shoppingPackage
    },
    packageList() {
      const { packageSelectTask } = this
      const listFieldName = this.getListFieldName()
      return packageSelectTask[listFieldName]
    },
    showTask() {
      // const { pPackage } = this
      const { packageSelectTask } = this
      const { origin, type } = packageSelectTask
      if (origin === 'sell') {
        return true
      }
      if (origin === 'shopping') {
        if (type === 'task') {
          return true
        }
        if (packageSelectTask.pPackage && type === 'package') {
          return true
        }
      }
      return false
    },
    showPackage() {
      const { packageSelectTask } = this
      const { origin, type, pPackage } = packageSelectTask
      if (origin === 'shopping') {
        if (type === 'package' && !pPackage) {
          return true
        }
      }
      return false
    }
  },
  watch: {
    origin: function(val) {
      console.log(val)
      this.initList()
    },
    type: function () {
      this.initList()
    }
  },
  mounted () {
    this.initList()
    const { packageSelectTask } = this
    const { taskList } = packageSelectTask
    packageSelectTask.selectedList = tools.deepClone(taskList)
  },
  methods: {
    scrollToTop() {
      const { $refs } = this
      const { mainGroup } = $refs
      mainGroup.scrollTop = 0
    },
    handleDoSearch() {
      this.clearSearchData()
      this.initList()
    },
    loadmore() {
      const { packageSelectTask } = this
      const {
        // search,
        origin,
        type
      } = packageSelectTask
      // if (search !== '') {
      //   this.clearSearchData()
      // }
      // 我销售的
      if (origin === 'sell') {
        this.getSellList()
      }
      console.log(this.packageSelectTask)
      // 商城
      if (origin === 'shopping') {
        // 教学包
        if (type === 'package') {
          this.getShoppingPackage()
        }
        // 模板
        if (type === 'task') {
          this.getShoppingTask()
        }
      }
    },
    clearSearchData() {
      const { packageSelectTask } = this
      const listFieldName = this.getListFieldName()
      const moreFieldName = this.getMoreFieldName()
      packageSelectTask[listFieldName] = []
      packageSelectTask[moreFieldName] = false
    },
    getListFieldName() {
      const { packageSelectTask } = this
      const {
        search,
        origin,
        type,
        pPackage
      } = packageSelectTask
      let listFieldName = ''
      if (origin === 'sell') {
        listFieldName = 'sellList'
        if (search !== '') {
          listFieldName = 'searchSellList'
        }
      }
      if (origin === 'shopping') {
        if (type === 'package') {
          listFieldName = 'shoppingPackage'
          if (search !== '') {
            listFieldName = 'searchPackageList'
          }
          if (pPackage) {
            listFieldName = 'packageTaskList'
            if (search !== '') {
              listFieldName = 'searchPackageTaskList'
            }
          }
        }
        if (type === 'task') {
          listFieldName = 'shoppingTask'
          if (search !== '') {
            listFieldName = 'searchShoppingTask'
          }
        }
      }
      return listFieldName
    },
    getMoreFieldName() {
      const { packageSelectTask } = this
      const {
        search,
        origin,
        type,
        pPackage
      } = packageSelectTask
      let moreFieldName = ''
      if (origin === 'sell') {
        moreFieldName = 'moreSellList'
        if (search !== '') {
          moreFieldName = 'moreSearchSellList'
        }
      }
      if (origin === 'shopping') {
        if (type === 'package') {
          moreFieldName = 'moreShoppingPackage'
          if (search !== '') {
            moreFieldName = 'moreSearchShoppingPackage'
          }
          if (pPackage) {
            moreFieldName = 'morePackageTaskList'
            if (search !== '') {
              moreFieldName = 'moreSearchPackageTaskList'
            }
          }
        }
        if (type === 'task') {
          moreFieldName = 'moreShoppingTask'
          if (search !== '') {
            moreFieldName = 'moreSearchShoppingTask'
          }
        }
      }
      return moreFieldName
    },
    async getPackageTaskList() {
      const { packageSelectTask } = this
      const listFieldName = this.getListFieldName()
      const moreFieldName = this.getMoreFieldName()
      packageSelectTask[listFieldName] = []
      packageSelectTask[moreFieldName] = false
      const res = await packageSelectTask.getPackageTaskList()
      const { message, contents } = res
      if (message === 'success') {
        const { taskList } = contents
        if (taskList && taskList.length) {
          packageSelectTask[listFieldName] = packageSelectTask[listFieldName].concat(listForClasses({
            list: taskList,
            classes: PTask
          }))
        }
        // if (!taskList || taskList.length < 20) {
        //   packageSelectTask[moreFieldName] = false
        // } else {
        //   packageSelectTask[moreFieldName] = true
        // }
      }
      console.log(packageSelectTask)
    },
    async getShoppingTask() {
      const { packageSelectTask } = this
      // if (packageSelectTask.loading) {
      //   return
      // }
      const {
        search
      } = packageSelectTask
      // packageSelectTask.loading = true
      const res = await packageSelectTask.getShoppingTask()
      // packageSelectTask.loading = false
      let listFieldName = 'shoppingTask'
      let moreFieldName = 'moreShoppingTask'
      if (search !== '') {
        listFieldName = 'searchShoppingTask'
        moreFieldName = 'moreSearchShoppingTask'
      }
      const { message, contents } = res
      if (message === 'success') {
        const { shoptasks } = contents
        if (shoptasks && shoptasks.length) {
          packageSelectTask[listFieldName] = packageSelectTask[listFieldName].concat(listForClasses({
            list: shoptasks,
            classes: PTask
          }))
        }
        if (!shoptasks || shoptasks.length < 20) {
          packageSelectTask[moreFieldName] = false
        } else {
          packageSelectTask[moreFieldName] = true
        }
      }
    },
    async getShoppingPackage() {
      const { packageSelectTask } = this
      const res = await packageSelectTask.getShoppingPackage()
      const listFieldName = this.getListFieldName()
      const moreFieldName = this.getMoreFieldName()
      const { message, contents } = res
      if (message === 'success') {
        const { shoppackages } = contents
        if (shoppackages && shoppackages.length) {
          packageSelectTask[listFieldName] = packageSelectTask[listFieldName].concat(listForClasses({
            list: shoppackages,
            classes: PPackage
          }))
        }
        // 没有更多了
        if (!shoppackages || shoppackages.length < 20) {
          packageSelectTask[moreFieldName] = false
        } else {
          packageSelectTask[moreFieldName] = true
        }
      }
      return
    },
    async getSellList() {
      const { packageSelectTask } = this
      const {
        search
      } = packageSelectTask
      const res = await packageSelectTask.getSellList()
      let listFieldName = 'sellList'
      let moreFieldName = 'moreSellList'
      if (search !== '') {
        listFieldName = 'searchSellList'
        moreFieldName = 'moreSearchSellList'
      }
      const { message, contents } = res
      if (message === 'success') {
        const { taskList } = contents
        if (taskList && taskList.length) {
          packageSelectTask[listFieldName] = packageSelectTask[listFieldName].concat(listForClasses({
            list: taskList,
            classes: PTask
          }))
        }
        // 没有更多了
        if (!taskList || taskList.length < 20) {
          packageSelectTask[moreFieldName] = false
        } else {
          packageSelectTask[moreFieldName] = true
        }
      }
      return
    },
    /**
     * 初始化列表 切换时清空search字段 不考虑搜索情况
     * @returns {Promise<void>}
     */
    async initList() {
      this.scrollToTop()
      const { packageSelectTask } = this
      packageSelectTask.loading = false
      const {
        search,
        origin,
        type,
        sellList,
        shoppingPackage,
        shoppingTask,
        pPackage
      } = packageSelectTask
      console.log(packageSelectTask)
      if (origin === 'sell') {
        if (search !== '') {
          this.getSellList()
          return
        }
        if (!sellList || sellList.length === 0) {
          console.log(sellList, sellList.length)
          this.getSellList()
          return
        }
      }
      if (origin === 'shopping') {
        if (type === 'package') {
          console.log(shoppingPackage)
          if (pPackage) {
            this.getPackageTaskList()
            return
          }
          if (search !== '') {
            this.getShoppingPackage()
            return
          }
          if (!shoppingPackage || shoppingPackage.length === 0) {
            this.getShoppingPackage()
            return
          }
        }
        if (type === 'task') {
          console.log('getShoppingTask')
          if (search !== '') {
            this.getShoppingTask()
            return
          }
          if (!shoppingTask || shoppingTask.length === 0) {
            this.getShoppingTask()
            return
          }
        }
      }
    },
    touchWrapper() {
      const { $refs } = this
      if ($refs.filterBar) {
        $refs.filterBar.switchOrigin = false
      }
    },
    clearFilterBarSearch() {
      const { $refs } = this
      if ($refs.filterBar) {
        $refs.filterBar.doClearSearch()
      }
    }


  }
}
</script>

<style scoped lang="scss">
.c_teaching_package_select_task_index{
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: #f8f8f8;
  .main_group{
    @include scrollbar;
    width: 100%;
    flex: 1;
    overflow: auto;
    position: relative;
    .sticky_group{
      position: sticky;
      width: 100%;
      background-color: #f8f8f8;
      top: 0;
      left: 0;
      z-index: 3;
      padding: 20px 20px 0;
    }
  }
}
</style>
