import { listConvertToModel } from '@/models'
import TaskInfo from '@/models/task/task_info'
let addNumber = 0
class CourseSection {
  id
  title
  createTime
  sort
  courceId
  taskids
  tasks
  addNumber // 自定义 用于记录增加的值

  constructor (params) {
    const {
      id,
      title,
      createTime,
      sort,
      courceId,
      taskids,
      tasks,
    } = params ?? {}
    this.id = id
    this.title = title
    this.createTime = createTime
    this.sort = sort
    this.courceId = courceId
    this.taskids = taskids
    this.addNumber = addNumber ++
    if (tasks) {
      this.tasks = listConvertToModel({
        list: tasks,
        model: TaskInfo
      })
    }
  }

  create () {

  }
}

export default CourseSection
