import request from '@/api/request'
import { listConvertToModel } from '@/models'
import CoursePackage from '@/models/cource_package'
const course_package = {
  state: {
    id: undefined, // 当前编辑的id
    data: undefined, // 当前编辑的教学包
    section: undefined, // 当前编辑的章节
    packageList: [], // 当前教学包列表
    checkForUpdates: false, // 检查更新
    task: undefined, // 当前课程预览的任务
    operationData: undefined, // 用于操作的数据
    // 专人审核方法
    personAuditMethod: undefined,
  },
  mutations: {
    setCoursePackageId (state, data) {
      state.id = data
    },
    setCoursePackageData (state, data) {
      state.data = data
    },
    /**
     * 设置课程包操作数据
     *
     * 该方法用于将课程包的操作数据更新到 Vuex store 中，以便在应用的不同部分之间共享操作数据
     * 主要用于记录当前正在进行的操作或交易的详细信息
     *
     * @param {Object} state - Vuex store 的状态对象，用于存储数据
     * @param {Object} data - 要设置的操作数据对象，包含课程包操作的详细信息
     */
    setCoursePackageOperationData (state, data) {
      state.operationData = data
    },



    setCoursePackageSection (state, data) {
      state.section = data
    },
    setCoursePackageList (state, data) {
      state.packageList = data
    },
    setCoursePackageCheckForUpdates (state, data) {
      state.checkForUpdates = data
    },
    setCoursePackageTask (state, data) {
      state.task = data
    },
    setCoursePackagePersonAuditMethod (state, data) {
      state.personAuditMethod = data
    },

  },
  actions: {
    /**
     * 获取场景
     * @param context
     * @param params
     * @returns {Promise<*>}
     */
    // async getARSceneList (context, params) {
    //   console.log(context, params)
    //   const res = await request.request(
    //     '/vr/myModelList',
    //     {
    //       pn: params.pn
    //     }
    //   )
    //   return res
    // },
    async getCoursePackageList (context, params) {
      const data = Object.assign(
        {},
        params,

      )
      // console.log(data, context)

      const res = await request.request(
        '/resource/courceList',
        data,
      )
      if (res.message === 'success') {
        const list = listConvertToModel({
          list: res.contents.page.result,
          model: CoursePackage,
        })
        context.commit('setCoursePackageList', list)
      }
      return res

    }
  }
}

export default course_package
