<template>
  <transition name="fade">
    <div class="notice_plugin" v-if="visible"
      :class="{mobile}"
      :style="{'z-index': zindex}"
    >
      <div class="mask" v-if="mask"></div>
      <div class="notice_group">
        <div
          v-if="!hide_icon"
          class="icon"
          :class="{success: type=='success'}"
        ></div>
        <div class="text">{{desc}}</div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
  data () {
    return {
      visible: false,
      desc: 'A description',
      mask: true,
      zindex: 2000,
      type: 'default',
      mobile: false,
      hide_icon: false,
    }
  },
  watch:{
    visible: function(val){
      if(val){
        this.zindex = this.$utils.getZIndex();
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.notice_plugin{
  .mask{
    z-index: 999999998;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    // background-color: red;
  }
  .notice_group{
    @include flex;
    @include bbox;
    z-index: 999999999;
    position: fixed;
    width: auto;
    top:50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 15px 36px;
    // height: 54px;
    background: rgba(0,0,0,0.7);
    border-radius: 4px;
    .icon{
      @include background;
      flex-shrink: 0;
      width: 20px;
      height: 20px;
      background-image: url(~@/assets/images/common/notice_default.png);
      margin-right: 10px;
      &.success{
        background-image: url(~@/assets/images/common/notice_success.png);
      }
    }
    .text{
      white-space: pre-wrap;
      font-size: 18px;
      color: #fff;
      line-height: 24px;
    }
  }
  &.mobile{
    .notice_group{
      width: 80%;
      // align-items: flex-start;
      justify-content: center;
      .text{
        font-size: 12px;
        // white-space: nowrap;
      }
    }
  }
}
</style>