const app = {
  state: {
    loading: false,
    arPermission: true, // ar编辑权限
    userInfo: undefined,
    // 开发标记
    isDev: true,
  },
  mutations: {
    setAppLoading (state, loading) {
      state.loading = loading
    },
    setAppArPermission (state, arPermission) {
      state.arPermission = arPermission
    },
    setAppUserInfo (state, userinfo) {
      state.userInfo = userinfo
    },
  },
  actions: {
  }
}

export default app
