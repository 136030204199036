import vue from '@/main';
import global from '@/api/global'
import {PTag} from '@/types'
import types from '@/utils/types'
import { updateModel } from '@/models'

class PTask{
  buy
  cancelflag
  chatId
  chatName
  chatType
  checkType
  checkUserId
  checkUserName
  checkstatus
  collectCount
  collectionflag
  colour
  controls
  courselist
  createTime
  createusername
  createuserthumbnail
  customFlag
  deleteDays
  deleteFlag
  draft
  endTime
  from
  fromchat
  hide
  id
  inFlag
  infolderflag
  introduction
  isremind
  level
  levelStatus
  likeCount
  likeFlag
  locknum
  major
  name
  ownedEnterprises
  packageId
  packageTitle
  preTaskId
  preuserid
  preusername
  price
  processStatus
  readNum
  readamount
  reason
  sale
  showTime
  showflag
  signUserId
  startTime
  tags
  taskDetail
  taskType
  taskchatnum
  tasks
  teachId
  teachType
  teachingType
  thumbnail
  tousers
  updateContent
  updateTime
  updateUserId
  updateusername
  updateuserthumbnail
  userId
  userName

  /**自定义字段 custom field */
  cf   // Object 存储各种额外参数
  create_num = 1 // 创建id
  cf_selected = false
  selected = false


  constructor(params){
    // const {id,
    //   chatType,
    //   chatId,
    //   name,
    //   userId,
    //   updateUserId,
    //   createTime,
    //   updateTime,
    //   updateContent,
    //   startTime,
    //   endTime,
    //   tousers,
    //   controls,
    //   preTaskId,
    //   level,
    //   locknum,
    //   taskDetail,
    //   userName,
    //   thumbnail,
    //   updateusername,
    //   updateuserthumbnail,
    //   taskType,
    //   signUserId,
    //   price,
    //   checkstatus,
    //   teachId,
    //   inFlag,
    //   buy,
    //   readNum,
    //   createusername,
    //   createuserthumbnail,
    //   from,
    //   colour,
    //   taskchatnum,
    //   infolderflag,
    //   hide,
    //   draft,
    //   deleteFlag,
    //   deleteDays,
    //   sale,
    //   showflag,
    //   readamount,
    //   introduction,
    //   tags,
    //   collectionflag,
    //   levelStatus,
    //   processStatus,
    //   cf,
    //   cf_selected,
    //   selected,
    //   checkType,
    // } = params || {};
    //
    // this.id = id
    // this.chatType = chatType
    // this.chatId = chatId
    // this.name = name
    // this.userId = userId
    // this.updateUserId = updateUserId
    // this.createTime = createTime
    // this.updateTime = updateTime
    // this.updateContent = updateContent
    // this.startTime = startTime
    // this.endTime = endTime
    // this.tousers = tousers
    // this.controls = controls
    // this.preTaskId = preTaskId
    // this.level = level
    // this.locknum = locknum
    // this.taskDetail = taskDetail
    // this.userName = userName
    // this.thumbnail = thumbnail
    // this.updateusername = updateusername
    // this.updateuserthumbnail = updateuserthumbnail
    // this.taskType = taskType
    // this.signUserId = signUserId
    // this.price = price
    // this.checkstatus = checkstatus
    // this.teachId = teachId
    // this.inFlag = inFlag
    // this.buy = buy
    // this.readNum = readNum
    // this.createusername = createusername
    // this.createuserthumbnail = createuserthumbnail
    // this.from = from
    // this.colour = colour
    // this.taskchatnum = taskchatnum
    // this.infolderflag = infolderflag
    // this.hide = hide
    // this.draft = draft
    // this.deleteFlag = deleteFlag
    // this.deleteDays = deleteDays
    // this.showflag = showflag
    // this.sale = sale
    // this.readamount = readamount
    // this.introduction = introduction
    // this.collectionflag = collectionflag
    // this.levelStatus = levelStatus
    // this.processStatus = processStatus
    // this.cf = cf
    // this.cf_selected = cf_selected ?? false
    // this.selected = true
    // this.checkType = checkType

    this.updateData(params)
    const {
      tags
    } = params
    if (tags && tags.length) {
      this.tags = types.transformObjectForList({
        list: tags,
        obj: PTag
      })
    }
  }


  updateData (params) {
    updateModel(params, this)
  }
  /**
   * 打开任务
   * @param {Object} config
   */
  open(config) {
    vue.$api.moduleTask.openTask({taskId: this.id, config: Object.assign({}, config)});
  }

  /**
   * 获取讲师上架课程
   * @param {*} params  keyword start userId
   * @returns
   */
  getLecturerShopTask = async (params={}) => {
    const {userId}         = this
    const {keyword, start} = params
    const data             = {
      userId,
      keyword,
      start: start ?? 0,
    }
    const res = await vue.$global.doPost({
      url: '/projectShop/teacherTasks',
      data,
    })
    const {message,contents} = res

    if(message === 'success'){
      return contents
    }
    return false
  }

  getAuditStatus(){
    const {checkstatus} = this
    let   color         = '#999'
    let   audit         = ''
    // 审核状态1未审核 2审核中 3审核通过 4审核不通过
    switch(checkstatus){
      case(1):
        color = '#999'
        audit = '未审核'
        break
      case(2):
        color = '#FAAD14'
        audit = '审核中'
        break
      case(3):
        color = '#05AD00'
        audit = '通过'
        break
      case(4):
        color = '#D82E0D'
        audit = '未通过'
        break
      default:
        color = '#999'
        audit = ''
        return false
    }
    return {color, audit}
  }

  /**
   * 上架
   */
  doPutaway = async (params={}) => {
    const {cb} = params
    // / projectShop/ upperTasks
    vue.$openConfirm({
      title   : '提示',
      desc    : '是否确认上架至商城？',
      sure    : '是',
      cancel  : '否',
      sureType: '',             // 确定按钮样式 '','warn'
      onSure  : async ()=>{
        const res = await vue.$global.doPost({
          url : '/projectShop/upperTasks',
          data: {taskids: this.id},
        })
        const {message} = res
        if(message === 'success'){
          this.showflag = 1
          vue.$notice({desc: '上架成功', type: 'success'})
          if(cb){
            cb()
          }
        }

      },
      onCancel: ()=>{},
    })
  }
  /**
   * 下架
   */
  doSoldOut = async (params={}) => {
    const {cb} = params
    vue.$openConfirm({
      title   : '提示',
      desc    : '是否确认从商城下架？\n模板将不在商城展示，但不影响已购买用户',
      sure    : '是',
      cancel  : '否',
      sureType: '',                                  // 确定按钮样式 '','warn'
      onSure  : async ()=>{
        const res = await vue.$global.doPost({
          url : '/projectShop/downTask',
          data: {taskid: this.id},
        })
        const {message} = res
        if(message === 'success'){
          this.showflag = 0
          vue.$notice({desc: '下架成功', type: 'success'})
          if(cb){
            cb()
          }
        }
      },
      onCancel: ()=>{},
    })
  }

  /**
   * 选择/取消
   */
  doSelect = () => {
    this.cf_selected = true
  }
  doDeselect = () => {
    this.cf_selected = false
  }

  freeToBuy = async () => {
    const { id, price } = this
    const data = {
      id,
      price,
      type: 1,   // 2教学包
    }
    const res = await vue.$global.doPost({
      url: '/projectTeach/buy',
      data,
    })
    const {message, contents} = res
    if(message === 'success'){
      this.buy = true
      return true
    }
    return false
  }

  /**
   * 免费领取
   * @returns
   */
  getFree = async () => {
    return this.freeToBuy()
  }

  /**
   * 任务来源
   * @returns '来源: 工作组' || ''
   */
  getSource = () => {
    let   source           = ''
    const {taskType, from} = this
    if((taskType == 1 || taskType == 3) && from){
      source += `来源: ${from}`
    }
    return source
  }
  /**
   * 增加颜色 CTASK
   */
  addColor = async (color)=>{
    const {colour, id:taskId} = this

    const data = {
      colour: '',
    }
    if(colour){
      data.colour += colour
      data.colour += ','
    }
    data.colour += color
    // TODO 判断是否发送 调用/新建 不发送
    if(taskId){
      data.taskId = taskId
      global.doPost({
        url: '/projectTask/setcolour',
        data,
        openNotice: false,
      })
    }
    this.colour = data.colour

    // TODO [关联更新] 操作区更新/我的模板页更新
  }
  /**
   * 移除颜色 [关联更新] CTASK
   */
  removeColor = async (color)=>{
    const {colour, id:taskId} = this
    const data                = {
      colour: '',
    }
    if(colour){
      const arr   = colour.split(',')
      const index = arr.findIndex(item=>{
        return item === color
      })
      if(index != -1){
        arr.splice(index, 1)
      }
      data.colour = arr.join(',')
    }
    // TODO 判断是否发送 调用/新建 不发送
    if(taskId){
      data.taskId = taskId
      global.doPost({
        url: '/projectTask/setcolour',
        data,
        openNotice: false,
      })
    }
    this.colour = data.colour

    // TODO [关联更新] 操作区更新/我的模板页更新
  }

  /**
   * 改变隐私
   */
  changePrivacy = async () => {
    const {id: taskId, hide} = this
    const res = await global.doPost({
      url: '/projectTask/setHide',
      data: {
        taskId,
        hide,
      },
      notice: '隐私模式设置失败'
    })
    return res
  }

  collect = async ()=>{
    const {id} = this
    vue.$collectSelectRoute({
      onSure: async (params)=>{
        const {p_folder} = params
        const res = await global.doPost({
          url: '/projectCustom/addFolderTask',
          data: {
            folderId: p_folder.id,
            addIds: id,
          }
        })
        if(res.message === 'success'){
          this.collectionflag = 1
          vue.$notice({desc:"收藏成功", type: 'success'})
        }
      }
    })
  }

  /**
   * 取消收藏
   */
  removeCollect = async (params) => {
    const { id } = this
    const { confirm } = params ?? {}
    if (confirm === false) {
      const res = await global.doPost({
        url: '/projectCustom/deleteFolderTask',
        data: { deleteIds: id }
      })
      if (res.message === 'success') {
        this.collectionflag = 0
        // vue.$notice({desc:"取消收藏", type: 'success'})
      }
      return res
    }
    vue.$openConfirm({
      title: '确定取消收藏？',
      desc: '',
      sure:'是',
      cancel:'否',
      onSure: async ()=>{
        const res = await global.doPost({
          url: '/projectCustom/deleteFolderTask',
          data: {deleteIds: id}
        })
        if(res.message === 'success'){
          this.collectionflag = 0
          vue.$notice({desc:"取消收藏", type: 'success'})
        }
      },
      onCancel:()=>{},
    })
  }

  /**
   * 分享任务
   */
  share = (params)=>{
    vue.$shareRoute({
      list: params?.list,
      work: params?.work,
      onSure: async (list)=> {
        console.log(list)
        vue.$closeShareRoute()
        // 确认分享
        this.shareConfirm({list, work: params?.work})
      }
    })
  }

  shareConfirm = (params) => {
    const {list, work} = params
    vue.$shareConfirm({
      list,
      work,
      onSure: async (data)=>{
        await this.postShare({list, work:data})
      },
      onReturn: async (data)=>{
        this.share({
          list: data.list,
          work: data.work,
        })
      }
    })
  }

  /**
   * 分享请求
   * @param {*} params
   */
  postShare = async (params) => {
    const {id, name} = this
    const {list, work} = params
    const {userIds, chatIds} = getShareIds({list});
    const data = {
      id,
      shareType:1,
      userIds,
      chatIds,
      work
    }
    try{
      const res = await shareTask(data);
      if(res){
        let messageContent = `分享 ${name}`;
        updateMessageBoxAfterShare({messageContent, list});
        // 判断是否追加消息列表
        this.addToMessageListAfterShare({shareType: 1, taskId: id, work});
        return true;
      }
    }catch(err){
      // console.log(err);
    }
    return false;
  }

  /**
   * 分享后追加消息列表
   * @param {*} params
   * @returns
   */
  addToMessageListAfterShare = (params)=>{
    const {name} = this
    const taskName = name;
    const get_id = vue.$api.moduleTask.getRouteParamsId();
    const get_type = vue.$tools.getChatType();
    const {shareType, taskId, work, } = params || {};
    const user = vue.$tools.getCookie('user');
    if(chatInMultipleContactsList()){
      vue.$api.task.addToMessageList({
        chat_id: get_id,
        type: get_type,
        createTime: new Date().getTime(),
        details: [],
        fromUserId: vue.$tools.getUserId(),
        logType: 1,
        shareType,
        taskId,
        taskName,
        thumbnail: user.thumbnail,
        userName: user.userName,
        work,
      });
    }
    return;
  }

  select = () => {
    const {cf} = this
    this.cf = Object.assign(
      cf??{},
      {
        selected: !(cf?.selected ?? false)
      }
    )
  }

  cancelSelect = () => {
    const {cf} = this
    this.cf = Object.assign(
      cf??{},
      {
        selected: false
      }
    )
  }

}

export default PTask


/**
 * 获取分享的id列表
 * @returns {userIds, chatIds}
 */
 function getShareIds(params){
  const {list} = params
  let chatIds = ''
  let userIds = ''
  list.forEach(item=>{
    if(item.type == 0){
      userIds += userIds ? `,${item.id}` : item.id
    }
    if(item.type == 1){
      chatIds += chatIds ? `,${item.id}` : item.id
    }
  })
  return {userIds, chatIds}
}


/**
 * 分享教学包/任务
 * @param {*} params
 */
 async function shareTask(params){
  // const {id, shareType, userIds, chatIds, work} = params || {};
  let res;
  try{
    res = await vue.$http.request('/projectTask/shareTask', params);
    if(res.message == 'success'){
      vue.$notice({desc: '分享成功', type:'success'});
      return true;
    }else{
      vue.$notice({desc: res.describe});
    }
  }catch(err){
    // console.log(err);
  }
  return false;
}

/**
 * 当前聊天在分享多选列表里面
 * @param {*} params
 * @returns
 */
function chatInMultipleContactsList(params){
  const {list} = params
  const {task} = vue.$api
  const get_id = task.getRouteParamsId()
  const get_type = vue.$tools.getChatType()
  if(list && list.length){
    const find = list.find(item => {
      return item.type == get_type && item.id == get_id
    })
    return find
  }
  return false
}
/**
 * 分享后更新消息盒子
 * @returns
 */
function updateMessageBoxAfterShare(params){
  const {messageContent, list} = params
  // console.log(multiple_contacts_list);
  list.forEach((item)=>{
    vue.$api.task.messageBoxUp({
      messageContent,
      updateusername: vue.$tools.getCookie('user').userName,
      lastSendTime: new Date().getTime(),
      photo: item.thumbnail,
      chatId: item.id,
      chatType: item.type,
    })
  })
  return ;
}



