var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "loading",
          rawName: "v-loading",
          value: _vm.appLoading,
          expression: "appLoading",
        },
      ],
      attrs: { id: "app" },
    },
    [
      _vm.isRouterAlive ? _c("router-view") : _vm._e(),
      0
        ? _c("div", { staticClass: "tetst_print_group" }, [
            _c("div", { staticClass: "print_group" }, [
              _c("div", { staticClass: "title" }, [_vm._v("测试打印标题")]),
              _c(
                "div",
                { staticClass: "list" },
                _vm._l(60, function (item) {
                  return _c("div", { key: item, staticClass: "unit" }, [
                    _vm._v("测试打印行"),
                  ])
                }),
                0
              ),
            ]),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }