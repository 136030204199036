/*
 * @Author       : Hugo
 * @Date         : 2020-06-05 14:13:21
 * @LastEditors  : hugo
 * @LastEditTime : 2022-07-20 15:27:11
 * @Description  : 基本请求
 * @FilePath     : /miaohang/src/api/request.js
 */
import axios from "axios";
import config from "@/api/config/config";
import VueCookies from 'vue-cookies';
import vue from '@/main.js';
import store from '@/store'

const instance = axios.create();
 //用户码


const request = {
  /**
   * 封装基础请求
   * @param {String} url 地址
   * @param {Object} params 参数
   */
  async basic_request(url, params, con){
    // if(vue.$tools.getCookie('user') && vue.$userid !== '' && vue.$userid != vue.$tools.getCookie('user').userId){
    //   config.stop && config.stop.cancel('取消请求');
    //   vue.$router.push({name:'login_sign'});
    //   return false;
    // }
    let conf = Object.assign(config, con)
    let res = await instance.post(url, params, conf);
    return res.data;
  },
  /**
   * 封装基础get请求
   * @param {*} url
   * @param {*} params
   */
  basic_get(url, params){
    let conf = Object.assign(config, params);
    instance.get(url, conf);
  },
  get(url, params){
    let loginUserCode = VueCookies.get('loginUserCode') || '';
    let IDENTIFYUSERCODE = VueCookies.get('IDENTIFYUSERCODE') || '';
    let config = {
      headers: {
        loginUserCode: JSON.stringify(loginUserCode),
        // IDENTIFYUSERCODE: JSON.stringify(IDENTIFYUSERCODE),
      },
    }
    let data = Object.assign(params, config);
    this.basic_get(url, data);
    // if(res.message == 'nologin'){
    //   vue.$router.push({name:'login_sign'});
    // } else if (res.message =='success') {
    //   return res;
    // } else {
    //   vue.$message({
    //     message: res.describe,
    //     type: 'warning'
    //   });
    // }

  },
  /**
   * 封装普通请求(添加用户码)
   * @param {String} url 地址
   * @param {Object} params  参数
   * @param {Boolean} openloading 是否开启loading
   */
  async request(url, params, openloading = false, ){
    // let loginUserCode = VueCookies.get('loginUserCode') || '';
    let loginUserCode = vue.$tools.getCookie('loginUserCode') || '';
    let IDENTIFYUSERCODE = vue.$tools.getCookie('IDENTIFYUSERCODE') || '';
    let config = {
      headers: {
        loginUserCode: JSON.stringify(loginUserCode),
        // IDENTIFYUSERCODE: JSON.stringify(IDENTIFYUSERCODE),
        // IDENTIFYUSERCODE: '111',
      },
    }
    // let loading;
    if(openloading){
      //loading
      // loading = vue.$loading({
      //   lock: true,
      //   text: '加载中',
      //   spinner: 'el-icon-loading',
      //   background: 'rgba(0, 0, 0, 0.2)'
      // });
      vue.$showLoading({title: '加载中'});
    }

    // 用户码 (移到请求头部)
    let data = {
      ...params,
    }
    let res = await this.basic_request(url, data, config);
    if(res.message == 'nologin'){
      const { $route } = vue
      const { query } = $route
      const { toResource } = query
      if ( Number(toResource) === 1) {
        const toResourceRoute = Object.assign(
          {},
          vue.$route
        )
        store.commit('set_toResourceRoute', toResourceRoute)
        vue.$router.push({name:'login_sign'});
      } else {
        vue.$router.push({name:'login_sign'});
      }
    } else if(res.message === 'updating'){
      window.location.href='/home/#/maintain';
    } else if (res.message =='success') {
      if(openloading){
        // loading.close();
        vue.$hideLoading();
      }
      return res;
    } else {
      return res;
    }
    if(openloading){
      // loading.close();
      vue.$hideLoading();
    }
    return res;
  },
  /**
   * 自定义请求
   * @param {*} url
   * @param {*} params
   * @param {*} config
   */
  async custom_request(url, params, config){
    let res;

    if(config){
      config.baseURL = process.env.VUE_APP_BASEURL;
      config.timeout = 1000000;
      res = await instance.post(url, params, config);
    }else{
      res = await instance.post(url, params);
    }
    return res;
  },
}
instance.interceptors.request.use(
  config => {
    const {login_user_id} = vue.$store.state;
    if(vue.$tools.getCookie('user') && login_user_id !== '' && login_user_id != vue.$tools.getCookie('user').userId){
      config.stop && config.stop.cancel('取消请求');
      vue.$notice({desc: '已登录其他用户'});
      vue.$router.push({name:'login_sign'});
    }
    return config;
  },
  err => {
    return Promise.reject(err);
  }
);

//http response 拦截器
instance.interceptors.response.use(
  response => {
    if (response.data) {
      /*判断版本更新 */
      newVeision(response);
      switch (response.status) {
        //     case 91000:
        //     case 91001:
        //     case 91002:
        //       Vue.prototype.$message.error({
        //         message: response.data.message || response.data.msg,
        //         onClose() {
        //           localStorage.removeItem("authorization");
        //           sessionStorage.clear();
        //           router.push("/login");
        //         }
        //       });
        //       break;
        case 200:
          return response;
        default:
          //   console.error(response.data.message || response.data.msg);
          return Promise.reject(response.data.message || response.data.msg);
      }
    }
  },
  error => {
    return Promise.reject(error);
  }
);

async function newVeision(res){
  // const {NODE_ENV} = process;
  const { data } = res || {}
  const { contents } = data || {}
  const { appdownDto } = contents || {}
  const { version:softVersion } = appdownDto || {}
  const { $version } = vue
  if(softVersion && softVersion !== $version){
    vue.$utils_notify.systemUpdate()
  }
}

export default request;
