import TaskInfo from '@/models/task/task_info'
import TaskDetail from '@/models/task/task_detail'
import TaskUser from '@/models/task/task_user'
import { listConvertToModel } from '@/models/index'
import store from '@/store'
class Task {
  taskInfo
  taskDetails = []
  taskUsers = []
  constructor (params) {
    const {
      taskInfo,
      taskDetails,
      taskUsers,
    } = params ?? {}
    this.taskInfo = new TaskInfo(taskInfo)
    if (taskDetails) {
      this.taskDetails = listConvertToModel({
        list: taskDetails,
        model: TaskDetail
      })
    }
    if (taskUsers) {
      this.taskUsers = listConvertToModel({
        list: taskUsers,
        model: TaskUser
      })
    }
  }

  // 初始化一组任务数据
  static initTask (params) {

    store.commit('setTasksInfo', params?.taskInfo)
    store.commit('setTaskDetails', params?.taskDeatails)
    store.commit('setTaskUsers', params?.taskUsers)
    const taskInfo = new TaskInfo(params?.taskInfo)
    const taskDetails = listConvertToModel({
      list: params?.taskDeatails,
      model: TaskDetail
    })
    const taskUsers = listConvertToModel({
      list: params?.taskUsers,
      model: TaskUser
    })

    store.commit('setTasksEditInfo', taskInfo)
    store.commit('setTasksEditDetails', taskDetails)
    store.commit('setTasksEditUsers', taskUsers)
    console.log(params)
  }

}

export default Task
