<template>
  <div class="v_pc_gift_card_modules_card bbox d-flex flex-column align-start"
    :class="{mobile}"
    :style="{
      'background-color': (pcard && pcard.cardpic)?'transparent':'#000',
      height
    }"
  >
    <img
      v-if="pcard && pcard.cardpic"
      :src="`${$img_url}${pcard.cardpic}`"
      alt=""
      class="cardpic"
      ref="cardpic"
    >
    <img
      v-if="pcard && pcard.cardpic && cardpic_base64"
      :src="cardpic_base64"
      alt=""
      class="cardpic_base64"
    >
    <div class="card_group bbox d-flex flex-column justify-space-between"
      :style="{
        width,
        height,
      }"
    >
      <div class="descript_group">
        <div class="type_group d-flex align-center justify-space-between">
          <img
            src="~@/assets/images/personal_center/gift_card/logo.png"
            alt=""
            class="logo"
          >
          <div class="type"
          >{{usetype}}</div>
        </div>

      </div>
      <div class="title"
        v-if="pcard && pcard.cardname"
        :style="{'font-size':title_size}"
      >{{pcard.cardname}}</div>
      <div class="info_group d-flex justify-space-between align-center">
        <div class="userful_life">{{indate}}</div>
        <div class="card_number"
          v-if="show_cardno&&cardno"
        >{{cardno}}</div>
      </div>

    </div>
    <div class="price_group bbox"
      v-if="show_price"
    >价格：{{price}}元</div>
  </div>
</template>

<script>
import moment from 'moment'
import files from '@/utils/files'
export default {
  props: {
    pcard: {
      type: Object,
      default: undefined,
    },
    width: {
      type: String,
      default: '296px',
    },
    height: {
      type: String,
      default: '170px',
    },
    show_price: {
      type: Boolean,
      default: false,
    },
    mobile: {
      type: Boolean,
      default: false,
    },
    font_plus: {
      type: Number,
      default: 0,
    },
    show_cardno: {
      type: Boolean,
      default: true,
    },
    pcarduser: {
      type: Object,
      default: undefined,
    },
    title_size: {
      type: String,
      default: '20px',
    },
    show_indate: {
      type: Boolean,
      default: true,
    },
    termOfValidity: {
      type: String,
      default: ''
    }

  },
  computed: {
    usetype(){
      const {pcard} = this
      if(!pcard){
        return ' '
      }
      const {usetype} = pcard
      if(Number(usetype) === 1){
        return '通用卡'
      }
      if(Number(usetype) === 2){
        return '新用户'
      }

      return '通用卡'

    },
    price(){
      const {pcard} = this
      const {price} = pcard
      if(price){
        return (price/100).toFixed(2)
      }
      return price
    },
    cardno(){
      const {pcarduser} = this
      if(!pcarduser){
        return false
      }
      return pcarduser.cardno
    },
    indate(){
      const {pcard, pcarduser, show_indate, termOfValidity} = this
      if(show_indate === false){
        return ''
      }
      if (termOfValidity) {
        let formatTime
        if (isNaN(Number(termOfValidity))) {
          formatTime = moment(String(termOfValidity)).format('yyyy-MM-DD')
        } else {
          formatTime = moment(Number(termOfValidity)).format('yyyy-MM-DD')
        }
        if (formatTime === 'Invalid date') {
          return ''
        }
        return `领取有效期至：${formatTime}`
      }
      if(pcarduser){
        const { termTime, useUserId, channel } = pcarduser
        if (termTime && (termTime < Date.now())){
          return `有效期至 ${this.$moment(termTime).format('YYYY-MM-DD')}`
        }
        if (channel === 0) {
          return '长期有效'
        }
        if (pcarduser && ((pcarduser.termTime ?? '') === '')) {
          return '长期有效'
        }
        if (pcarduser.pcard && (pcarduser.pcard.termdays === 0 || (pcarduser.pcard.termdays ?? '') === '')) {
          return '长期有效'
        }
        if (useUserId) {
          return `有效期 ${pcard.termdays} 天`
        }
        if (termTime) {
          return `有效期至 ${this.$moment(termTime).format('YYYY-MM-DD')}`
        }
      }
      if(pcard){
        const {termdays} = pcard
        if(termdays){
          return `有效期 ${termdays} 天`
        }
      }
      return ''
    },

  },
  data(){
    return{
      cardpic_base64: '',
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    init(){
      this.generateBase64Pic()
    },
    async generateBase64Pic(){
      const {pcard} = this
      if(!pcard){
        return
      }
      const {cardpic} = pcard
      if(cardpic){
        files.urlToBase64(`${this.$img_url}${cardpic}`, (base64)=>{
          this.cardpic_base64 = base64
          console.log(base64)
        })
      }
    },
    // 将网络图片转换为本地图片，解决因跨域导致画布无法转换为图片问题
    getImageInfo (url) {
      return new Promise((resolve, reject) => {
        var xhr = new XMLHttpRequest()
        xhr.open('get', url, true)
        xhr.responseType = 'blob'
        xhr.onload = function() {
          if (this.status === 200) {
            var blob = this.response
            var fileReader = new FileReader()
            fileReader.onloadend = function(e) {
              var result = e.target.result
              resolve(result)
            }
            fileReader.readAsDataURL(blob)
          }
        }
        xhr.onerror = function() {
          reject()
        }
        xhr.send()
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.v_pc_gift_card_modules_card{
  @include background();
  border-radius: 10px;
  background: {
    size: 100% 100%;
  }
  position: relative;
  .cardpic{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 2;
    border-radius: 10px;
  }
  .cardpic_base64{
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
    border-radius: 10px;
  }
  .card_group{
    position: relative;
    z-index: 4;
    border-radius: 10px;

    color: #fff;
    padding: 0 14px;
    .descript_group{
      width: 100%;
      padding-top: 17px;
      .type_group{
        width: 100%;
        .type{
          border: 1px solid #F7E5C7;
          color: #F7E5C7;
          font-size: 14px;
          line-height: 24px;
          padding: 0 12px;
          border-radius: 17px;
        }
      }

    }
    .title{
      width: 100%;
      font-size: 20px;
      // padding-top: 20px;
      line-height: 36px;
    }
    .info_group{
      width: 100%;
      padding-bottom: 16px;
      font-size: 12px;
      padding-top: 10px;
      .useful_life{
        color: #fff;
      }
    }
  }
  .price_group{
    font-size: 16px;
    color: #666;
    margin-top: 10px;
    width: 100%;
    padding: 0 10px;
  }
}
</style>
