const pageStatus = {
  state: {
    // 资源库
    resource: {
      // 0 课程中心 1 资源中心 3 资源库门户 3 模板商城
      pageType: 0,
    },
    // 我的资源
    myResource: {
      // template 任务 course 免费课程 package 付费课程 teach_task 教学任务
      pageTag: 'template',
      teachingType: 1, // 1 学校 2 企业 3 付费
    },
  },
  mutations: {
    setResourcePageStatus (state, data) {
      // 如果未传值, 则默认为课程中心
      if (data === undefined) {
        data = {
          pageType: 0,
        }
      }
      state.resource = data
    },
    setMyResourcePageStatus (state, data) {
      console.log(data)
      // 如果未传值, 则默认为任务
      if (data === undefined) {
        data = {
          pageTag: 'template',
          teachingType: 1, // 1 学校 2 企业 3 付费
        }
      }
      state.myResource = data
    },
  },
  actions: {
    setResourcePageStatus (context, data) {
      context.commit('setResourcePageStatus', data)
    }
  }
}

export default pageStatus
