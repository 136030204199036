import common from '@/api/common';
import control from '@/api/control';
import task from '@/api/task';
import test from '@/api/test'; //测试
import task_api from '@/api/task_api';
import radar from '@/api/radar'; //雷达
import SHARE from '@/api/share'; //分享
import PACKAGE from '@/api/package'; // 教学包
import CUSTOM from '@/api/custom'; // 自定义分类
// import UTILS from '@/api/utils'; // 自定义分类
import editTask from '@/api/editTask'; // 编辑任务
import teaching from '@/api/teaching'; // 教学
import draft from '@/api/draft'; // 草稿
import chat from './chat';
import messageList from './modules/message_list';
import moduleTask from './modules/task';
import tag from './modules/tag';
import taskList from './modules/task_list';
import dialog from './modules/dialog/index.js';
import reminder from './modules/reminder';
import oss from './modules/oss';
import consulting from './modules/consulting';
import recycle_bin from './modules/recycle_bin';
import user from './modules/user';
import search from './modules/search';
import version from './modules/version';
import app from './modules/app';
import input from './modules/input';
import at from './modules/at';
import payment from './modules/payment';
import shopping_mail from './modules/shopping_mail';
import teaching_package from './modules/teaching_package';
import download from './modules/download/index';
import wisdom from './modules/wisdom/index';
import ctask from './modules/ctask/index';

export default {
  ...common,
  ...control,
  ...task,
  ...test, //测试
  task: task_api, // this.$api.task.***();
  radar: radar,
  share: SHARE,
  package: PACKAGE,
  custom: CUSTOM,
  // utils: UTILS,
  editTask,
  teaching,
  draft,
  chat,
  messageList,
  moduleTask,
  tag, // 
  taskList, // 任务列表
  dialog, // 聊天相关
  reminder, // 提醒
  oss,
  consulting,
  recycle_bin,
  user,
  search,
  version, // 版本
  app,
  input,
  at,
  payment,
  shopping_mail,
  teaching_package,
  download,
  wisdom,
  ctask,
};