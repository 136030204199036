/*
 * @Author       : Hugo
 * @Date         : 2021-06-17 14:15:58
 * @LastEditTime : 2021-10-26 14:29:44
 * @LastEditors  : Hugo
 * @FilePath     : /miaohang/src/api/teaching_resources.js
 * @Description  :
 * @^_^          :
 */
import vue from '@/main.js';

class Teaching{
  constructor(){
  }
  /**
   * 上传文件列表
   * @param {object} 参数 {files_list:文件列表, type: '文件类型video/file/hyperlink', taskId: 任务id, all: 是否全部上传[默认false]}
   */
  async uploadFilesList({files_list, type, taskId = null, all = false}){
    for(let i in files_list){
      // console.log(files_list);

      if(files_list[i]){

        // if(all || !files_list[i].teachUrl){
        //   // 全部上传 || 没有教学url
        //   if(type === 'video' || type === 'file'){
        //     await this.addFile(files_list[i], taskId);
        //   }
        //   await this.addTeachFile(files_list[i], type, taskId, all);
        // }
        if(type === 'video' || type === 'file'){
          if(all || !files_list[i].teachUrl){
            // 全部上传 || 没有教学url
            await this.addFile(files_list[i], taskId);
            await this.addTeachFile(files_list[i], type, taskId, all);
          }
        }else{
          await this.addTeachFile(files_list[i], type, taskId, all);
        }
      }
    }
  }
  /**
   * @description: 添加文件到
   * @param {type}
   * @return {type}
   */
  async addFile(item, taskId){
    const {draft_id} = vue.$store.state;
    let addFileData = {
      id: 0, // 控件id
      filename: item.filename,
      chatId: item.chatId,
      taskId:  taskId || draft_id ,
      filetype: item.filetype,
      filesize: item.filesize,
      width: '',
      height: '',
      teach: 1, // 教学附件
      url: item.url,
    }
    let addFileRes = await vue.$http.request('/projectTask/addFile', addFileData);
    // // console.log(addFileRes);
    vue.$set(item, 'teachUrl', addFileRes.contents.fileId);
    vue.$set(item, 'id', addFileRes.contents.fileId);
    return;
  }

  /**
   * @description: 添加教学附件
   * TODO 需要传入taskID
   * @param {type}
   * @return {type}
   */
  async addTeachFile(item, type, taskId, all = false){
    const {draft_id, create_draft_teaching, edit_draft_teaching} = vue.$store.state;
    // console.log(!item.init || (item.init && item.mod) || all)
    // 不是初始化数据 || (是初始化 并且修改了) || 全部上传
    if(!item.init || (item.init && item.mod) || all){
      let data_type = 1;
      let content = item.teachUrl;
      if(type == 'file'){
        data_type = 2;
      }else if(type == 'hyperlink'){
        data_type = 3;
        content = item.teachUrl;
      }
      let {task_data} = vue.$store.state;
      let id = task_data.id;
      // if(task_data.taskType == 3){
      //   taskId = task_data.teachId;
      // }
      let data = {
        taskId: id,
        // taskId: this.aduit_teaching.taskId,
        type: data_type,
        content: content,
      }
      // // console.log(create_draft_teaching, edit_draft_teaching)
      if(create_draft_teaching || edit_draft_teaching){
        data.taskId = draft_id;
      }
      if(taskId){
        data.taskId = taskId;
      }
      // 超链接需要先删除再添加 && 不是在创建草稿的时候
      if(type == 'hyperlink' && item.id && !create_draft_teaching){
        await vue.$http.request('/projectTeach/deleteTeachFile', {ids: item.id});
      }
      await vue.$http.request('/projectTeach/addTeachFile', data);
    }else{
      return ;
    }
    return;
  }

}

const teaching = new Teaching();
export default teaching;

