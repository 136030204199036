import vue from '@/main.js'
import sendTaskFun from './sendTaskFun'
import store from '@/store'


class EditTask{
  main = async (params) => {

    const {
      checkstatus,
      checkUserId,
      checkType,
    } = params ?? {}
    let url = '/projectTask/editTask';
    const { task, task_config, task_original } = vue.$store.state
    const {
      edit_teach, price, edit_draft, edit_instance, public_notice,introduction,tags,
      teachType
    } = task_config
    const { levelStatus, processStatus, taskType } = task.task
    // 标签变化
    const { addtags,deletetagIds } = getTags({old_tags: task.task.tags, new_tags: tags})
    if (task_config.task_name === '' && !public_notice) {
      vue.$notice({desc: '请输入任务标题'})
      return false
    }
    if (sendTaskFun.uploaded() === false) {
      vue.$notice({desc: '有文件正在上传, 请稍候再发布'})
      return false
    }

    let data = {
      taskId: task.task.id,
      name: task.task.name === task_config.task_name ? '': task_config.task_name,   // 标题没变化传''
      task_type: task_config.task_type,
      hide: task_config.privacy ? 1                      : 0,
      // to_users    : sendTaskFun.generateToUsers(),
      controls: sendTaskFun.generateControls(),
      chat_type: task_config.chat_type,
      chat_id: sendTaskFun.generateChatId(),
      // addtags     : task_config.addtags,
      // deletetagIds: task_config.deletetagIds,
      addtags,
      deletetagIds,
      sortJson: sendTaskFun.generateSortJson(),
      locknum: task.task.locknum,
      add_users: task.task.add_users,
      delete_users: task.task.delete_users,
      // levelStatus,
      // processStatus
      // 接口特殊字段 改为小写
      checkuserid: checkUserId,
      checkType,
    }
    if (String(levelStatus) !== String(task_original.task.levelStatus)) {
      data.levelStatus = levelStatus
    }
    if (String(processStatus) !== String(task_original.task.processStatus)) {
      data.processStatus = processStatus
    }
    if(edit_teach){
      data.price        = price;
      data.to_users     = '';
      data.introduction = introduction;
      delete data.hide;
      delete data.color;
    }
    if(edit_draft){
      url = 'projectDraft/draftEdit';
      data.price = price;
      delete data.chat_type;
      delete data.chat_id;
      delete data.hide;
      delete data.locknum;
      delete data.to_users;

    }
    if(edit_instance){
      delete data.price;
    }
    if(public_notice){
      url = '/projectChat/editNotice';
      delete data.chat_type;
      delete data.hide;
      delete data.color;
      delete data.to_users;
      delete data.price;
      delete data.name;
      delete data.taskId;
      delete data.task_type;
      delete data.addtags;
      delete data.deletetagIds;
      delete data.locknum;
    }

    if (taskType === 2) {
      url = 'resource/updateTeachTask'
      data.teachType = teachType
      // if (checkType !== 1) {
      if (checkstatus) {
        data.checkstatus = checkstatus
      } else {
        data.checkstatus = task.task.checkstatus
      }
      // }

    }
    // return;
    const res = await vue.$global.doPost({
      url,
      data,
      notice: '发布失败',
      preventDuplicateClicks: true,
    })

    await sendTaskFun.checkReminder({res});
    return res;

  }


}


/**
 * 生成新增标签和删除标签
 * @param {*} params
 * @returns
 */
function getTags(params={}){
  const {old_tags, new_tags} = params;
  let addtags = '';
  let deletetagIds = '';
  // 旧的不存在为新增
  if(new_tags && new_tags.length){
    new_tags.forEach(item => {
      if(old_tags && old_tags.length){
        const is_in_old = old_tags.find(unit=>{
          return unit.tagName === item.tagName;
        })
        if(is_in_old === undefined){ // 不存在 则新增
          addtags += `${addtags===''?'':','}${item.tagName}`;
        }

      }else{ // 旧的不存在 全部为新增
        addtags += `${addtags===''?'':','}${item.tagName}`;
      }
    })
  }
  // 新的不存在为删除
  if(old_tags && old_tags.length){
    old_tags.forEach(item => {
      if(new_tags && new_tags.length){
        const is_in_new = new_tags.find(unit=>{
          return unit.tagName === item.tagName;
        })
        if(is_in_new === undefined){ // 不存在 则删除
          if(item.id){
            deletetagIds += `${deletetagIds===''?'':','}${item.id}`;
          }
        }
      }else{ // 新的不存在, 全部为删除
        if(item.id){
          deletetagIds += `${deletetagIds===''?'':','}${item.id}`;
        }
      }
    })
  }

  return {addtags,deletetagIds}

}

const editTask = new EditTask();

export default editTask.main;


/**
name:
to_users: 4360,4378,4379
chat_type: 1
chat_id: 132
controls: [{"id":56933,"type":1,"remark":"13","content":"","unit":null,"islock":1,"userlock":"4360","updatenum":0,"islink":false,"controlLinkJson":[],"fileJson":[],"sort":1}]
task_type: 3
hide: 0
sortJson: []
taskId: 7234
locknum: 0

name:
to_users: 4360,4378,4379,4416,22
chat_type: 1
chat_id: 132
taskId: 6611
controls: [{"id":51583,"type":1,"remark":"234233","content":"","sort":1,"timetype":null,"unit":null,"islock":1,"userlock":"4360","updatenum":0,"controlLinkJson":null,"fileJson":[],"sortJson":[]}]
task_type: 3
hide: 0
sortJson: []
locknum: 0
addtags:
deletetagIds:



 */
