/*
 * @Author       : Hugo
 * @Date         : 2020-07-13 14:22:48
 * @LastEditors  : hugo
 * @LastEditTime : 2024-01-20 09:22:07
 * @Description  :
 * @FilePath     : /pmcs_web/src/api/config/config.js
 */
import Qs from "qs";
import VueCookies from 'vue-cookies';
// import vue from '@/main.js';

 //用户码
let loginUserCode = VueCookies.get('loginUserCode') || '';
let IDENTIFYUSERCODE = VueCookies.get('IDENTIFYUSERCODE') || ''
// import { BASE_URL } from "./baseurl";
export default {
  // baseURL: process.env.NODE_ENV === 'production' ? 'https://project.ourutec.com/api' : process.env.VUE_APP_BASEURL,
  baseURL: process.env.VUE_APP_BASEURL,
  method: 'POST',
  transformRequest: [
    function(data) {
      data.params = JSON.stringify(data.params);
      data = Qs.stringify(data);
      return data;
    }
  ],
  transformResponse: [
    function(data) {
      return data;
    }
  ],
  headers: {
    "Content-Type": "application/x-www-form-urlencoded",
    loginUserCode: JSON.stringify(loginUserCode),
    // IDENTIFYUSERCODE: JSON.stringify(IDENTIFYUSERCODE),
  },
  params: {},
  paramsSerializer: function(params) {
    return Qs.stringify(params);
  },
  data: {
    // EID:"8484662",
    // Method:"kis.APP003678.ueanalysis.UEStockAnalysis.",
    // NetID:"8484662159954",
    // params: {}
  },
  timeout: 1000000,
  withCredentials: false, // default不携带cookie信息
  responseType: "json", // default

  // onUploadProgress: function (progressEvent) {
  //     // Do whatever you want with the native progress event
  // },
  onDownloadProgress: function() {
    // Do whatever you want with the native progress event
  },
  maxContentLength: 20000,
  validateStatus: function(status) {
    return status >= 200 && status < 300; // default
  },
  maxRedirects: 5000 // default
};
