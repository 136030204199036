import { updateModel } from '@/models'

class TaskInfo {
  buy
  cancelflag
  chatId
  chatName
  chatType
  checkstatus
  collectionflag
  colour
  controls
  createTime
  createusername
  createuserthumbnail
  deleteDays
  deleteFlag
  draft
  endTime
  from
  fromchat
  hide
  id
  inFlag
  infolderflag
  introduction
  isremind
  level
  levelStatus
  locknum
  name
  packageId
  packageTitle
  preTaskId
  preuserid
  preusername
  price
  processStatus
  readNum
  readamount
  reason
  sale
  showTime
  showflag
  signUserId
  startTime
  tags
  taskDetail
  taskType
  taskchatnum
  tasks
  teachId
  teachType
  thumbnail
  tousers
  updateContent
  updateTime
  updateUserId
  updateusername
  updateuserthumbnail
  userId
  userName


  constructor (params) {
    this.updateData(params)
  }

  updateData (params) {
    updateModel(params, this)
  }

}

export default TaskInfo
