var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "sketch_picture_page" },
    [
      _vm.img_list &&
      _vm.img_list.length &&
      _vm.msg.files != "" &&
      _vm.msg.filesNum != 0
        ? [
            _c(
              "div",
              { staticClass: "list d-flex flex-wrap" },
              [
                _vm._l(_vm.img_list, function (item, index) {
                  return [
                    index < 5
                      ? _c("el-image", {
                          staticClass: "picture flex-shrink-0",
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            lazy: true,
                            src: `${item}`,
                            fit: "cover",
                            "preview-src-list": _vm.img_list,
                          },
                          nativeOn: {
                            click: function ($event) {
                              return _vm.$api.stopPropagation($event)
                            },
                          },
                        })
                      : _vm._e(),
                  ]
                }),
                _vm.six_status
                  ? _c(
                      "div",
                      {
                        staticClass: "picture six_group",
                        on: {
                          click: function ($event) {
                            return _vm.$api.stopPropagation($event)
                          },
                        },
                      },
                      [
                        _vm.is_more && !_vm.switch_more
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "more_group d-flex align-center justify-center",
                                on: {
                                  click: function ($event) {
                                    return _vm.getMore($event)
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "text" }, [
                                  _vm._v("+" + _vm._s(_vm.more_number)),
                                ]),
                              ]
                            )
                          : _vm._e(),
                        _c("el-image", {
                          staticClass: "six flex-shrink-0",
                          staticStyle: { width: "100px", height: "100px" },
                          attrs: {
                            src: _vm.img_list[5],
                            fit: "cover",
                            "preview-src-list": _vm.img_list,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.switch_more
                  ? [
                      _vm._l(_vm.img_list, function (item, index) {
                        return [
                          index > 5
                            ? _c("el-image", {
                                key: index,
                                staticClass: "picture flex-shrink-0",
                                staticStyle: {
                                  width: "100px",
                                  height: "100px",
                                },
                                attrs: {
                                  src: `${item}`,
                                  fit: "cover",
                                  "preview-src-list": _vm.img_list,
                                },
                                nativeOn: {
                                  click: function ($event) {
                                    return _vm.$api.stopPropagation($event)
                                  },
                                },
                              })
                            : _vm._e(),
                        ]
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm.is_more && _vm.switch_more
              ? _c(
                  "div",
                  {
                    staticClass: "pack_up",
                    on: {
                      click: function ($event) {
                        _vm.switch_more = false
                        _vm.$api.stopPropagation($event)
                      },
                    },
                  },
                  [_vm._v("收起")]
                )
              : _vm._e(),
          ]
        : _c("common-sketch", { attrs: { msg: _vm.msg } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }