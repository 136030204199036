/*
 * @Author: Hugo
 * @Date: 2020-06-15 08:41:09
 * @LastEditTime : 2022-08-30 11:43:47
 * @LastEditors  : hugo
 * @Description:
 * @FilePath     : /miaohang/src/api/tools.js
 * @^_^
 */
import VueCookies from 'vue-cookies';
import vue from '@/main.js';
let tools = {
  /**
   * 关键词高亮
   * @param {String} str 需要转换的文本
   * @param {String} keyword 关键词
   */
  // EXAM[str]: '1Bug,2BUG,3bug';
  // EXAM[template]: 'bug'
  highlightKeywords(str,keyword){
    const Reg = new RegExp(keyword, 'gi');
    const mark_str = `#*keyword${keyword}keyword*#`; //利用keyword标记一个特殊的字段
    if(keyword){
      if (str) {
        const insert_str_arr = str.match(Reg); // ['Bug','BUG','bug'] ***** length => 3;
        // 将字符串转成固定格式
        str = str.replace(Reg, `<span style="color: #0054A7;">${mark_str}</span>`); // '1<span style="color: #00B3FE;">bug</span>,2<span style="color: #00B3FE;">bug</span>,3<span style="color: #00B3FE;">bug</span>';
        // 将字符串按标记字符${mark_str}切割成数组;
        const content_arr = str.split(mark_str); // ['1<span style="color: #00B3FE;">','</span>,2<span style="color: #00B3FE;">','</span>,3<span style="color: #00B3FE;">','</span>'] ***** length => 4
        let new_str = '';
        // 拼接新字符串
        if(content_arr && content_arr.length && insert_str_arr && insert_str_arr.length){
          content_arr.forEach( (item, index) => {
            new_str += content_arr[index] ? content_arr[index] : '';
            new_str += insert_str_arr[index] ? insert_str_arr[index] : '';
          })
        }
        // // console.log(insert_str_arr);
        // // console.log(str);
        // // console.log(content_arr);
        // // console.log(new_str);
        return new_str || str;

      }
    }else{
      return str;
    }
  },
  // 获取用户id
  getUserId(){
    //用户码
    let loginUserCode = VueCookies.get('loginUserCode');
    // let IDENTIFYUSERCODE = VueCookies.get('IDENTIFYUSERCODE');
    if(!loginUserCode){
    // if(!IDENTIFYUSERCODE){
      if(localStorage.getItem('loginUserCode')){
        loginUserCode = JSON.parse(localStorage.getItem('loginUserCode'));
      }
      // if(localStorage.getItem('IDENTIFYUSERCODE')){
      //   IDENTIFYUSERCODE = JSON.parse(localStorage.getItem('IDENTIFYUSERCODE'));
      // }
      if(sessionStorage.getItem('loginUserCode')){
        loginUserCode = JSON.parse(sessionStorage.getItem('loginUserCode'));
      }
      // if(sessionStorage.getItem('IDENTIFYUSERCODE')){
      //   IDENTIFYUSERCODE = JSON.parse(sessionStorage.getItem('IDENTIFYUSERCODE'));
      // }
    }
    if(!loginUserCode){
      return false
    }
    // if(!IDENTIFYUSERCODE){
    //   return false
    // }

    let { userId } = loginUserCode;
    // let { userId } = IDENTIFYUSERCODE;

    return userId;
  },
  /**
   * 获取cookie
   * @param {*} name
   */
  getCookie(name){
    let cookie = VueCookies.get(name);
    if(!cookie){
      if(sessionStorage.getItem(name)){
        cookie = sessionStorage.getItem(name);
      }
      if(localStorage.getItem(name)){
        cookie = localStorage.getItem(name);
      }
    }
    if(this.isJson(cookie)){
      cookie = JSON.parse(cookie);
    }
    return cookie;
  },
  /**
   * 设置cookie
   * @param {String} key
   * @param {*} val
   */
  setCookie(key, val){
    var type = getType(val);
     /** 存储在sessionStorage中 */
    if(typeof(Storage)!=="undefined"){
        // 是的! 支持 localStorage  sessionStorage 对象!
        // 一些代码.....
      console.log(val, key)

        if(type == 'array' || type == 'object'){
          localStorage.setItem(key, JSON.stringify(val));
          sessionStorage.setItem(key, JSON.stringify(val));
        }else{
          localStorage.setItem(key, val);
          sessionStorage.setItem(key, val);
        }
    } else {
        // 抱歉! 不支持 web 存储。
      VueCookies.set(key, val);
    }
  },
  /**
   * 移除cookie
   * @param {*} name
   */
  removeCookie(name){
    vue.$cookies.remove(name);
    localStorage.removeItem(name);
    sessionStorage.removeItem(name);
  },
  /**
   * 深拷贝
   * @param {*} data
   */
  deepClone(data){
    return deepClone(data);
  },
  /**
   * 获取后缀名
   * @param {*} name
   * @return str
   */
  // getExtName(name){
  //   let reg = /[^\.]\w*$/;
  //   let extname = name.match(reg);
  //   return extname;
  // },
  /**
   * 获取后缀名
   * @param {String} str 文件名
   * @return {String} extname 后缀名
   */
  getExtName(str){
    let extReg = /.+[\/\.]/;
    let extname = str.replace(extReg, '');
    extname = extname.toLowerCase();
    if(extname == 'docx' || extname == 'doc'){
      extname = 'doc';
    }else if (extname == 'xlsx' || extname == 'xls') {
      extname = 'xls';
    }else if (extname == 'pptx' || extname == 'ppt') {
      extname = 'ppt';
    }else if (extname == 'pdf'){
      extname = 'pdf';
    }else{

      let pic = ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'svg', 'tiff', 'heic'];
      // let zip = '.JAR.ZIP.RAR.7Z.ISO.TAR.CAB.UUE';
      let zip = ['JAR','ZIP','RAR','7Z','ISO','TAR','CAB','UUE']
      let mp4 = ['mp4','flv','f4v','webm','m4v','mov','3gp','3g2','rm','rmvb','wmv','avi','asf','mpg','mpeg','mpe','ts','div','dv','divx','vob','dat','mkv','swf','lavf','cpk','dirac','ram','qt','fli','flc','mod'];
      if(pic.indexOf(extname.toLowerCase()) != -1){
        // 图片
        extname = 'pic';
      }else if(zip.indexOf(extname.toUpperCase()) != -1){
        // 压缩文件
        extname = 'zip';
      }else if(mp4.indexOf(extname.toLowerCase()) != -1){
        extname = 'mp4';
      }else{
        // 未知
        extname = 'unknown';
      }
    }

    // // console.log(extname, str);
    return extname;
  },
  /**
   * 获取拓展名
   * @param {*} name
   * @returns
   */
  getRealExtName(name){
    let extReg = /.+[\/\.]/;
    let extname = name.replace(extReg, '');
    extname = extname.toLowerCase();
    return extname;
  },
  /**
   * 将好友列表进行分组 按首字符
   * @param {Object} friends_list 好友列表
   * @return: 分组后的数据
   */
  packetFriendsList(friends_list) {
    // // console.log(friends_list)
    let group_obj = {};
    for (let i in friends_list) {
      let firstword = friends_list[i].firstword.toLowerCase();
      if (!group_obj[firstword]) {
        group_obj[firstword] = [friends_list[i]];
      } else {
        group_obj[firstword].push(friends_list[i]);
      }
    }
    // 对象键名排序
    var newData = {};
    Object.keys(group_obj)
      .sort()
      .map(key => {
        newData[key] = group_obj[key];
      });
    return newData;
  },
  /**
   * 路由跳转
   * @param {Object} obj 跳转参数{name: '', params: {}}
   */
  routeTo(obj){
    vue.$router.push(obj);
  },
  /**
   * 判断字符串是否为json
   * @param {*} str
   */
  isJson(str){
    if (typeof str == 'string') {
      try {
        var obj=JSON.parse(str);
        // // console.log('转换成功：'+obj);
        return true;
      } catch(e) {
        // // console.log('error：'+str+'!!!'+e);
        return false;
      }
    }
    return false;
  },
  /**
   * 获取聊天类型
   * @return {Number} 0/1/-1
   */
  getChatType(){
    let route = vue.$route;
    let name = route.name;
    let chatType = -1;
    if(name == 'fun_task_dialog_single'){
      chatType = 0;
    }else if(name == 'fun_task_dialog_group'){
      chatType = 1;
    }
    return chatType;
  },
  /**
   * 编码base64
   * @param {*} str
   * @returns
   */
  btoa(str){
    let new_str = '';
    if(str){
      new_str = window.btoa(str);
    }
    return new_str;
  },
  /**
   * 解码base64
   * @param {*} str
   * @returns
   */
  atob(str){
    let new_str = '';
    if(str){
      new_str = window.atob(str);
    }
    return new_str;
  },
};


/**
 * 深拷贝
 * @param {*} data 数据
 */
function deepClone(data){
  var type = getType(data);
  var obj;
  if(type === 'array'){
      obj = [];
  } else if(type === 'object'){
      obj = {};
  } else {
      //不再具有下一层次
      return data;
  }
  if(type === 'array'){
      for(var i = 0, len = data.length; i < len; i++){
          obj.push(deepClone(data[i]));
      }
  } else if(type === 'object'){
      for(var key in data){
          obj[key] = deepClone(data[key]);
      }
  }
  return obj;
}
/**
 * 判断data类型array/object
 * @param {*} data
 */
function getType(data){
  let aim = Object.prototype.toString.call(data);
  if(aim === '[object Object]'){
    return 'object';
  }
  if(aim === '[object Array]'){
    return 'array';
  }
  return false;
  // // console.log(Object.prototype.toString.call(a) === '[object Object]');//判断对象 返回true
  // // console.log(Object.prototype.toString.call(b) === '[object Array]');//判断数组 返回t
}
export default tools;
