<template>
  <div
    v-if="visible"
  >
    <consulting-form-page

      :title="title"
      :loginUserCode="loginUserCode"
      :user="user"
      @handleClose="handleClose();"
    >

    </consulting-form-page>
  </div>
</template>

<script>
import ConsultingFormPage from '@/components/PopUp/identificationCode/Free.vue';
export default {
  components: {
    ConsultingFormPage,
  },
  data(){
    return{
      visible      : false,
      title        : undefined,
      user         : undefined,
      loginUserCode: undefined,
    }
  },
  mounted(){
    // // console.log(this.user);
  },
  methods: {
    handleClose(){
      this.visible = false;
    }
  }
}
</script>
